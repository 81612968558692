import { Box, Grid2, List, ListItem, Stack, Typography } from "@mui/material";
import { SlowLoadingImage } from "../../../components/common/NovoSlowLoadingImage";

export const TermsAndConditions: React.FunctionComponent = () => {
  const listStyle = { listStyle: "decimal", pl: 4 };
  const listItemStyle = { display: "list-item", lineHeight: 2.5, fontSize: 18 };
  const paragraph = { lineHeight: 2.5, pt: 2, fontSize: 18 };

  return (
    <>
      <Box sx={{ py: 2 }} display={"flex"} flexDirection='column' alignItems='center'>
        <SlowLoadingImage assetPath='NOVO-SEC-TBG-L.png' width={300} />
      </Box>
      <Grid2 container pb={10} px={2}>
        <Grid2 flex={1} />
        <Grid2 size={{ xs: 12, md: 10, lg: 8 }}>
          <Stack alignContent={"center"} spacing={2}>
            <Box pb={4} display={"flex"} flexDirection='column' alignItems='center' />
            <Typography variant='h4' pt={2}>
              Nutzungsvereinbarung für NOVO-Endkunden
            </Typography>
            <Typography sx={paragraph}>
              Wir, die NOVO Building GmbH, c/o Antler Innovation GmbH, Jägerstraße 32, 10117 Berlin, (nachfolgend „wir“, „uns“ oder „NOVO“), bieten mit unserem
              Dienst „NOVO OS“ eine Software für Finanzierer und Bauherren energetischer Bauprojekte an („<b>App</b>“).
            </Typography>
            <Typography sx={paragraph}>
              Die App erleichtert die Planung und Finanzierung energetischer Sanierungsmaßnahmen und ist eine Schnittstelle zwischen den Bauherren bzw.
              Vorhabenträger („<b>Kunden</b>“), den Energieberatern von NOVO sowie den Finanzierern der Vorhaben („<b>Bank</b>“). Kunden können in der App ihre
              Daten zum energetischen Bauprojekt („<b>Vorhaben</b>“) eingeben, welche durch die Energieberater von NOVO genutzt werden, um alle für das Vorhaben
              relevanten Energieberatungsleistungen zu erbringen (u.a. Ausstellung von Sanierungsfahrplänen und Energieausweisen). Die Vorhabendaten können an
              die Bank zur Erstellung eines Finanzierungsangebots weitergeleitet werden. Zu diesem Zweck schließen die Parteien diese Nutzungsvereinbarung.
            </Typography>
            <Box>
              <List sx={listStyle} dense>
                <ListItem sx={listItemStyle}>Der Kunde erhält unentgeltlich einen webbasierten Zugang zur App von NOVO zur Verfügung gestellt.</ListItem>
                <ListItem sx={listItemStyle}>
                  Um die App nutzen zu können, muss sich der Kunde registrieren und diese Nutzungsvereinbarung akzeptieren.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Der Kunde hat nach der Registrierung die Möglichkeit, vorhabenspezifische Leistungen (insbes. Energieberatung, individueller
                  Sanierungsfahrplan) auszuwählen, welche von NOVO erbracht werden. Hierzu besteht für den Kunden die Möglichkeit, die vorhabenspezifischen
                  Daten innerhalb der App zu hinterlegen. Der Kunde kann die von ihm hinterlegten Daten jederzeit überprüfen und ggf. berichtigen. Anschließend
                  kann er die vorhabenspezifischen Leistungen beauftragen, welche u.a. die Übermittlung der vorhabenspezifischen Daten an die Energieberater von
                  NOVO beinhaltet. Nach der Bearbeitung durch NOVO werden die Ergebnisse (insbes. individueller Sanierungsfahrplan, Energieausweis,
                  Förderantrag) dem Kunden mitgeteilt. Mit dem Einverständnis des Kunden werden dessen Eingaben, der Bearbeitungsstatus der Energieberatung und
                  dessen Ergebnisse an die Bank weitergeleitet. Das Finanzierungangebot der Bank erhält der Kunde anschließend über die App von NOVO oder auf
                  separatem Wege von seiner Bank.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Für den Kunden entstehen keine Kosten für die Nutzung der App. Für die vorhabenspezifischen Leistungen (u.a. Sanierungsfahrplan,
                  Energieausweis) erhält der Kunde von NOVO ein Angebot als Selbstzahler oder er erhält ein Angebot der Bank zur Kostenübernahme.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Der Kunde versichert, dass seine Angaben in der App insbesondere zu den vorhabenspezifischen Daten zutreffend sind. Die Eingabe unzutreffender
                  Daten kann strafrechtliche Konsequenzen zur Folge haben (insbes. im Falle von Subventionsbetrug).
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Der Kunde hat technische Geräte und damit zusammenhängende Zusatzdienste, die für die kundenseitige Verbindung mit, den kundenseitigen Zugriff
                  auf oder die sonstige Nutzung der App durch den Kunden erforderlich sind, auf eigene Kosten zu beschaffen und instand zu halten. Dies betrifft
                  insbesondere Hardware, Software, Netzwerke und mobile Endgeräte, für dessen Sicherheit und Nutzung allein der Kunde verantwortlich ist.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Der Kunde erhält ein nicht-exklusives, nicht übertragbares Recht zur Nutzung der App für den persönlichen Gebrauch im Rahmen dieser
                  Nutzungsvereinbarung. Eine kommerzielle Nutzung oder Weitergabe an Dritte ist nicht gestattet.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Die Anwendung der App ist auf das Vorhaben des Kunden beschränkt, soweit nicht gesetzliche Ausnahmeregelungen bestehen. Mit der Nutzung der
                  App (insbes. mit dem Download der App) erwirbt der Kunde keinerlei Urheber- oder gewerblichen Schutzrechte, es sei denn, diese wurden explizit
                  eingeräumt.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Es gilt die gesetzliche Haftung. NOVO haftet nicht für die Richtigkeit und Vollständigkeit der vom Kunden eingegebenen Daten und für sich
                  daraus etwaig ergebende Folgen.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  NOVO wird die personenbezogenen Daten des Kunden gemäß den geltenden Datenschutzgesetzen verarbeiten. Die Daten, die der Kunde in die App
                  eingibt, werden ausschließlich zur Erfüllung der gewünschten vorhabenspezifischen Leistungen und zur Weiterleitung an die finanzierende Bank
                  verwendet. Weitere Informationen zur Datenverarbeitung finden sich im Datenschutzhinweis am Ende dieser Nutzungsvereinbarung.
                </ListItem>
                <ListItem sx={listItemStyle}>
                  Es gilt das Recht der Bundesrepublik Deutschland mit Ausnahme des UN-Kaufrechts und des Kollisionsrechts.
                </ListItem>
              </List>
            </Box>
            <Typography variant='h4' pt={2}>
              Datenschutzhinweis:
            </Typography>
            <Typography sx={paragraph}>
              Verantwortlich für die Datenverarbeitung in der App ist die NOVO Building GmbH, c/o Antler Innovation GmbH, Jägerstraße 32, 10117 Berlin,
              Deutschland.
            </Typography>
            <Typography sx={paragraph}>
              Die Kategorien der verarbeiteten personenbezogenen Daten sind Name, E-Mail-Adresse, Postanschrift und Telefonnummer des Kunden sowie die vom
              Kunden eingegebenen Daten zum Vorhaben.
            </Typography>
            <Typography sx={paragraph}>
              Der Zweck der Verarbeitung ist die Erfüllung der Nutzungsvereinbarung, die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DSGVO.{" "}
            </Typography>
            <Typography sx={paragraph}>
              Empfänger der verarbeiteten Daten ist im Falle der Weiterleitung an die Bank (neben dem für die Verarbeitung Verantwortlichen) auch die Bank.{" "}
            </Typography>
            <Typography sx={paragraph}>
              Die Daten werden nach Wegfall der Verarbeitungszwecke, längstens jedoch bis zu 10 Jahre nach dem Ende der vertraglichen Beziehung gespeichert.{" "}
            </Typography>
            <Typography sx={paragraph}>
              Als betroffene Person im Sinne der DSGVO haben Sie Rechte gegenüber dem Verantwortlichen. Sie können von dem Verantwortlichen eine Bestätigung
              darüber verlangen, ob und welche Sie betreffenden personenbezogenen Daten verarbeitet werden (u. a. einschließlich der Zwecke, für die die
              personenbezogenen Daten verarbeitet werden; Kategorien von personenbezogenen Daten; Empfänger; Speicherdauer). Darüber hinaus können Sie unter
              bestimmten Umständen die Berichtigung oder Löschung Ihrer Daten verlangen. Sie haben auch das Recht, die Verarbeitung Ihrer Daten einzuschränken,
              und das Recht, die von Ihnen bereitgestellten Daten in einem strukturierten, allgemein verwendeten und maschinenlesbaren Format zu erhalten. Sie
              haben das Recht, eine Beschwerde bei einer Aufsichtsbehörde einzureichen.{" "}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 flex={1} />
      </Grid2>
    </>
  );
};
