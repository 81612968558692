import { Typography } from "@mui/material";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpSchnellcheckInProgress({ row }: RowDetailProps) {
  return (
    <Typography>
      {row.status == IsfpProcessStatus.SCHNELLCHECK_STARTED && (
        <>
          Schnellcheck wird ausgefüllt
          {row.eventTimestamps?.schnellcheckStartedOn && (
            <>
              . Er wurde am <i>{formatDate(row.eventTimestamps?.schnellcheckStartedOn, "dd.MM.yyyy")} </i> begonnen
            </>
          )}
          .
        </>
      )}
    </Typography>
  );
}
