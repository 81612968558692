import Grid from "@mui/material/Grid2";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useImage from "../../../../hooks/useImage";
import FormScreen from "../FormScreen";
import { useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { HouseInformation, HouseTypology } from "../../../../types/RenovationQuestionnaire";
import NovoTextField from "../../../../components/common/NovoTextField";
import { HouseType } from "../../../../types/Schnellcheck";

export default function GeneralInfoScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const [typologyError, setTypologyError] = useState<string>();
  const [unitsError, setUnitsError] = useState<string>();
  const [isWegError, setIsWegError] = useState<string>();
  const [workingCapitalError, setWorkingCapitalError] = useState<string>();
  const [plannedExpansionError, setPlannedExpansionError] = useState<string>();
  const [houseInformation, setHouseInformation] = useState<HouseInformation>(renovations.answers?.houseInformation || {});

  const validate = () => {
    if (!houseInformation.houseTypology) {
      setTypologyError("Pflichtfeld");
      return false;
    } else {
      setTypologyError(undefined);
    }
    if (!houseInformation.units) {
      setUnitsError("Pflichtfeld");
      return false;
    } else if (houseInformation.units < 1 || houseInformation.units > 20) {
      setUnitsError("Muss zwischen 1 und 20 liegen");
      return false;
    } else {
      setUnitsError(undefined);
    }
    if (renovations.houseType === "multiFamily") {
      if (houseInformation.multiFamily?.isOwnerAssociation === undefined) {
        setIsWegError("Pflichtfeld");
        return false;
      } else {
        setIsWegError(undefined);
      }
      if (houseInformation.multiFamily?.workingCapital === undefined) {
        setWorkingCapitalError("Pflichtfeld");
        return false;
      } else {
        setWorkingCapitalError(undefined);
      }
    }
    if (houseInformation.plannedExpansion === undefined) {
      setPlannedExpansionError("Pflichtfeld");
      return false;
    } else {
      setPlannedExpansionError(undefined);
    }
    return true;
  };
  const validateAndSave = () => {
    if (validate()) {
      const newState = { ...renovations, answers: { ...renovations.answers, houseInformation } };
      setRenovations(newState);
      next(newState);
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 1: </strong>Informationen zum Haus
          </Typography>
        </Grid>
        <Grid size={12}>
          <TypeSelector
            houseTypology={houseInformation.houseTypology}
            setHouseTypology={(houseTypology: HouseTypology) => {
              setHouseInformation({ ...houseInformation, houseTypology });
              setTypologyError(undefined);
            }}
            typologyError={typologyError}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Stack direction='row'>
            <NovoTextField
              fullWidth
              variant='outlined'
              id='house-units-input'
              label='Anzahl der Wohneinheiten'
              required={true}
              type='number'
              error={!!unitsError}
              helperText={unitsError}
              slotProps={{
                htmlInput: { min: 1, max: 20 },
              }}
              value={houseInformation.units || ""}
              onChange={(event) => {
                setHouseInformation({ ...houseInformation, units: +event.target.value });
                setUnitsError(undefined);
              }}
            />
            <Tooltip title='Eine Wohneinheit hat einen eigenen abschließbaren Eingang,  ein eigenes Bad und eine Küche.'>
              <HelpOutlineOutlinedIcon sx={{ fontSize: "1rem", ml: 1, alignSelf: "top" }} />
            </Tooltip>
          </Stack>
        </Grid>
        {renovations.houseType === HouseType.multiFamily && (
          <Grid size={12}>
            <Stack
              spacing={2}
              sx={{
                border: "1px solid #e0e0e0",
                //bgcolor: "#fafafa",
                p: 2,
                borderRadius: 2,
              }}
            >
              <Stack direction='row'>
                <ApartmentIcon sx={{ width: 20, height: 20 }} />
                <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 12, pl: 1, alignSelf: "end" }}>
                  Mehrfamilienhaus
                </Typography>
              </Stack>
              <Stack direction='row' spacing={4}>
                <Stack>
                  <Stack direction='row' alignSelf='center' alignItems='baseline' spacing={2} flexGrow={2}>
                    <Typography fontSize={{ xs: 14, lg: 16 }}>Sind Sie eine WEG?</Typography>
                    <ToggleButtonGroup
                      size='small'
                      exclusive
                      value={houseInformation.multiFamily?.isOwnerAssociation}
                      onChange={(event, value) => {
                        const multiFamily = { ...houseInformation.multiFamily, isOwnerAssociation: value === null ? undefined : value };
                        setHouseInformation({ ...houseInformation, multiFamily });
                        setIsWegError(undefined);
                      }}
                    >
                      <ToggleButton value={true} sx={{ fontSize: { xs: 10, lg: 13 } }}>
                        Ja
                      </ToggleButton>
                      <ToggleButton value={false} sx={{ fontSize: { xs: 10, lg: 13 } }}>
                        Nein
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                  {!!isWegError && (
                    <Box sx={{ mt: 1, borderTop: (theme) => (isWegError ? `1px solid ${theme.palette.error.main}` : "") }}>
                      <FormHelperText error>{isWegError}</FormHelperText>
                    </Box>
                  )}
                </Stack>
                <NovoTextField
                  variant='outlined'
                  id='working-capital-input'
                  label='Rücklagen'
                  required={true}
                  type='number'
                  error={!!workingCapitalError}
                  helperText={workingCapitalError}
                  slotProps={{
                    htmlInput: { min: 0 },
                    input: {
                      endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                    },
                  }}
                  value={houseInformation.multiFamily?.workingCapital || ""}
                  onChange={(event) => {
                    const multiFamily = { ...houseInformation.multiFamily, workingCapital: +event.target.value };
                    setHouseInformation({ ...houseInformation, multiFamily });
                    setWorkingCapitalError(undefined);
                  }}
                  sx={{
                    flexGrow: 1,
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
        )}
        <Grid size={{ xs: 12 }}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography fontSize={{ xs: 14, lg: 16 }}>Ist im Zuge der Sanierung ein Anbau/Ausbau geplant?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={houseInformation.plannedExpansion}
              onChange={(event, value) => {
                setHouseInformation({ ...houseInformation, plannedExpansion: value === null ? undefined : value });
                setPlannedExpansionError(undefined);
              }}
            >
              <ToggleButton value={true} sx={{ fontSize: { xs: 10, lg: 13 } }}>
                Anbau geplant
              </ToggleButton>
              <ToggleButton value={false} sx={{ fontSize: { xs: 10, lg: 13 } }}>
                kein Anbau geplant
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (plannedExpansionError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!plannedExpansionError && <FormHelperText error>{plannedExpansionError}</FormHelperText>}
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={isUpdateRunning}
      progressLabel='Hausinformationen'
      progressValue={8}
      content={content}
      helpContent={helpContent}
    />
  );
}

function TypeSelector({
  houseTypology,
  setHouseTypology,
  typologyError,
}: {
  houseTypology?: HouseTypology;
  setHouseTypology: (houseTypology: HouseTypology) => void;
  typologyError?: string;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const detached = useImage("Detached.png");
  const semi = useImage("Semi.png");
  const terraced = useImage("Terraced.png");
  return (
    <FormControl fullWidth>
      <Grid
        container
        flexGrow={1}
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction='row'
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%", backgroundColor: houseTypology === "detached" ? "#00000014" : "" }}
            onClick={() => setHouseTypology("detached")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={detached.image} height={isMobile ? 30 : 45} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                freistehend
              </Typography>
            </Stack>
            <Checkbox
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              checked={houseTypology === "detached"}
              onChange={() => setHouseTypology("detached")}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction='row'
            alignItems='center'
            sx={{
              p: { xs: 1, md: 2 },
              cursor: "pointer",
              height: "100%",
              backgroundColor: houseTypology === "semiDetached" ? "#00000014" : "",
            }}
            onClick={() => setHouseTypology("semiDetached")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={semi.image} height={isMobile ? 25 : 40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenendhaus / Doppelhaushälfte
              </Typography>
            </Stack>
            <Checkbox
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              checked={houseTypology === "semiDetached"}
              onChange={() => setHouseTypology("semiDetached")}
            />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack
            component={Paper}
            direction={"row"}
            alignItems='center'
            sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%", backgroundColor: houseTypology === "terraced" ? "#00000014" : "" }}
            onClick={() => setHouseTypology("terraced")}
          >
            <Stack alignItems={"start"} spacing={1} flexGrow={1}>
              <img src={terraced.image} height={isMobile ? 25 : 40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Reihenmittelhaus
              </Typography>
            </Stack>
            <Checkbox
              sx={{ "&.Mui-checked": { color: "text.primary" } }}
              checked={houseTypology === "terraced"}
              onChange={() => setHouseTypology("terraced")}
            />
          </Stack>
        </Grid>
        <Grid size={12} sx={{ mt: { xs: -1, lg: -2 }, borderTop: (theme) => (typologyError ? `1px solid ${theme.palette.error.main}` : "") }}>
          {!!typologyError && <FormHelperText error>{typologyError}</FormHelperText>}
        </Grid>
      </Grid>
    </FormControl>
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Der Haustyp hat einen
      <br />
      entscheidenden Einfluss auf
      <br />
      Wärmeverluste und sinnvolle
      <br />
      Empfehlungen zur Modernisierung.
      <br />
      <br />
      Und hier noch eine gute Nachricht:
      <br />
      Auch bestimmte Anbauten können
      <br />
      unter Umständen Förderung erhalten.
    </Typography>
  );
}
