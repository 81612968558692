import { useCallback } from "react";
import { apiEndpoint } from "../utils/params";
import { useAuthAxios } from "./useAuthAxios";

export default function useTriggerMetricsEmail() {
  const { authAxios, error, running, responseStatus } = useAuthAxios();

  const triggerMetricsEmail = useCallback(async () => {
    return await authAxios({
      method: "POST",
      baseURL: `${apiEndpoint()}/monthly-tenant-metrics-report`,
    });
  }, [authAxios]);

  return { triggerMetricsEmail, triggerMetricsEmailError: error, triggerMetricsEmailLoading: running, triggerResponseStatus: responseStatus };
}
