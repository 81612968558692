import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { RenovationsScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import NovoFileUpload from "../../../../components/common/NovoFileUpload";
import FormScreen from "../FormScreen";
import { HouseDocuments, UpsertQuestionnaireRequest } from "../../../../types/RenovationQuestionnaire";
import { useState } from "react";
import useUpsertRenovations from "../../../../hooks/useUpsertRenovations";

export default function PlansAndDocumentsUploadScreen({ renovations, setRenovations, isUpdateRunning, prev, next }: RenovationsScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [uploading, setUploading] = useState<boolean>(false);
  const [plansError, setPlansError] = useState<string>();
  const [drawingsError, setDrawingsError] = useState<string>();
  const [houseDocuments, setHouseDocuments] = useState<HouseDocuments>(renovations.answers?.houseDocuments || {});
  const { uploadFile } = useUpsertRenovations();
  const validate = () => {
    if (!houseDocuments.plans || houseDocuments.plans.length === 0) {
      setPlansError("Pflichtfeld");
      return false;
    } else {
      setPlansError(undefined);
    }
    if (!houseDocuments.drawings || houseDocuments.drawings.length === 0) {
      setDrawingsError("Pflichtfeld");
      return false;
    } else {
      setDrawingsError(undefined);
    }
    return true;
  };
  const upload = async ({ plans, drawings, miscellaneous, energyCertificate }: HouseDocuments) => {
    setUploading(true);
    if (plans) {
      for (const plan of plans) {
        if (!plan.file) continue;
        await uploadFile(plan);
      }
    }
    if (drawings) {
      for (const drawing of drawings) {
        if (!drawing.file) continue;
        await uploadFile(drawing);
      }
    }
    if (miscellaneous) {
      for (const misc of miscellaneous) {
        if (!misc.file) continue;
        await uploadFile(misc);
      }
    }
    if (energyCertificate && energyCertificate.file) {
      await uploadFile(energyCertificate);
    }
    setUploading(false);
  };
  const validateAndSave = () => {
    if (validate()) {
      upload(houseDocuments).then(() => {
        const newState: UpsertQuestionnaireRequest = { ...renovations, answers: { ...renovations.answers, houseDocuments }, isFinal: true };
        setRenovations(newState);
        next(newState);
      });
    }
  };

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1' fontSize={{ xs: 14, lg: 16 }}>
            <strong>Schritt 11: </strong>Gebäudepläne
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoFileUpload
            multiple
            label='Grundrisse'
            accept='image/jpeg,image/png,.pdf'
            tooltip='Max. 4 Dateien'
            direction={isMobile ? "column" : "row"}
            required
            value={houseDocuments.plans}
            onChange={(plans) => {
              setHouseDocuments({ ...houseDocuments, plans });
              setPlansError(undefined);
            }}
            error={!!plansError}
            helperText={plansError}
          />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload
            multiple
            label='Schnitte'
            accept='image/jpeg,image/png,.pdf'
            tooltip='Damit sind Zeichnungen der Seitenansichten Ihres Hauses gemeint. Max. 4 Dateien'
            direction={isMobile ? "column" : "row"}
            required
            value={houseDocuments.drawings}
            onChange={(drawings) => {
              setHouseDocuments({ ...houseDocuments, drawings });
              setDrawingsError(undefined);
            }}
            error={!!drawingsError}
            helperText={drawingsError}
          />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload
            multiple
            label='Sonstige'
            accept='*/*'
            tooltip='Baubeschreibung / Gebäudegutachten / Sonstige Unterlagen. Max. 4 Dateien'
            direction={isMobile ? "column" : "row"}
            value={houseDocuments.miscellaneous}
            onChange={(miscellaneous) => {
              setHouseDocuments({ ...houseDocuments, miscellaneous });
            }}
          />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload
            multiple
            label='Energieausweis'
            accept='image/jpeg,image/png,.pdf'
            direction={isMobile ? "column" : "row"}
            maxFiles={1}
            value={houseDocuments.energyCertificate ? [houseDocuments.energyCertificate] : []}
            onChange={(energyCertificates) => {
              const energyCertificate = energyCertificates.pop() || undefined;
              setHouseDocuments({ ...houseDocuments, energyCertificate });
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <FormScreen
      prev={prev}
      next={validateAndSave}
      isUpdateRunning={uploading || isUpdateRunning}
      progressLabel='Pläne'
      progressValue={91}
      content={content}
      helpContent={helpContent}
    />
  );
}

function helpContent() {
  return (
    <Typography
      fontSize={11}
      fontWeight={400}
      sx={{
        position: "relative",
        top: 50,
        left: 20,
      }}
    >
      Die Gebäudepläne werden für das
      <br />
      3D-Modell Ihres Gebäudes benötigt.
      <br />
      Anhand dieses Modells berechnen wir
      <br />
      energetische Verbesserungen und
      <br />
      erarbeiten die einzelnen
      <br />
      Sanierungsschritte in Ihrem
      <br />
      Sanierungsfahrplan.
    </Typography>
  );
}
