import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import useSetIsfpOfferAcceptance from "../../../../hooks/useSetIsfpOfferAcceptance";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";

export default function IsfpAcceptOffer({ row, onChange }: RowDetailProps) {
  const [isOfferManagementOpen, setOfferManagementOpen] = useState(false);

  const toggleOfferAcceptanceModal = () => {
    setOfferManagementOpen(!isOfferManagementOpen);
  };

  return (
    <>
      {row.status == IsfpProcessStatus.OFFER_REQUESTED && (
        <Box pt={2} display={"flex"} flexDirection={"column"}>
          <Typography>Kunde hat ein Angebot angefordert. Das Angebot wird dem Kunden (vorläufig manuell) unterbreitet.</Typography>
          <Box display={"inline"} pt={2}>
            <ButtonWithSpinner
              data-cy={`manage-offer-accepted-bnt-${row.leadEmail}`}
              variant={"contained"}
              label='Angebot verwalten'
              onClick={toggleOfferAcceptanceModal}
            />
          </Box>
        </Box>
      )}
      <OfferManagementModal row={row} open={isOfferManagementOpen} handleClose={toggleOfferAcceptanceModal} onChange={onChange} />
    </>
  );
}

const OfferManagementModal = ({ row, onChange, open, handleClose }: RowDetailProps & { open: boolean; handleClose: () => void }) => {
  const { isfpProcess, setIsfpProcess, error, running, postSetOfferAcceptance } = useSetIsfpOfferAcceptance();

  const [offerStatus, setOfferStatus] = useState<IsfpProcessStatus.OFFER_ACCEPTED | IsfpProcessStatus.OFFER_REJECTED | undefined>();
  const [formError, setFormError] = useState<string>();

  useEffect(() => {
    if (isfpProcess && onChange) {
      onChange(isfpProcess);
      onGoingToClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isfpProcess]);

  const onGoingToClose = () => {
    if (running) {
      return;
    }
    setOfferStatus(undefined);
    setIsfpProcess(undefined);
    handleClose();
  };

  const onRadioChange = (_: React.ChangeEvent<HTMLInputElement>, value: IsfpProcessStatus.OFFER_ACCEPTED | IsfpProcessStatus.OFFER_REJECTED) => {
    setFormError(undefined);
    setOfferStatus(value);
  };

  const handleSetOfferStatus = () => {
    if (!offerStatus) {
      setFormError("Bitte wähle eine der Optionen aus");
      return;
    }
    postSetOfferAcceptance(row.id, offerStatus);
  };

  return (
    <Dialog open={open} onClose={onGoingToClose} data-cy='update-offer-status-modal'>
      <DialogTitle>
        iSFP Angebot Verwaltung
        <Button
          sx={{ float: "inline-end", color: "rgba(0, 0, 0, 0.54)", ":hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" } }}
          onClick={onGoingToClose}
          data-cy='update-offer-status-cancel-btn'
          endIcon={<CloseIcon />}
        >
          Schließen
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Stack display={"inline"}>
          <Typography pb={2}>
            Hat der Kunde das Angebot akzeptiert, wird nach dem Speichern eine E-Mail mit einem Link zum iSFP-Fragebogen geschickt.
          </Typography>
          <FormControl error={!!formError}>
            <FormLabel id='demo-radio-buttons-group-label'> Angebot wurde vom Kunden:</FormLabel>
            <RadioGroup aria-labelledby='demo-radio-buttons-group-label' value={offerStatus} onChange={onRadioChange}>
              <FormControlLabel data-cy='offer-accepted-radio-btn' value={IsfpProcessStatus.OFFER_ACCEPTED} control={<Radio />} label='Akzeptiert' />
              <FormControlLabel data-cy='offer-rejected-radio-btn' value={IsfpProcessStatus.OFFER_REJECTED} control={<Radio />} label='Abgelehnt' />
            </RadioGroup>
            {!!formError && <FormHelperText>{formError}</FormHelperText>}
          </FormControl>
          {!!error && (
            <Alert data-cy='update-offer-status-error' sx={{ marginTop: 2 }} severity='error'>
              Es gab leider ein Fehler beim Speichern..
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={onGoingToClose} disabled={running}>
          Abbrechen
        </Button>
        <ButtonWithSpinner data-cy='update-offer-status-btn' variant={"contained"} loading={running} label='Speichern' onClick={handleSetOfferStatus} />
      </DialogActions>
    </Dialog>
  );
};
