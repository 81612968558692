import HelpIcon from "@mui/icons-material/Help";
import { Box, Button, FormControl, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChangeEvent, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import NovoAddressAutocomplete, { includesHouseNumber } from "../../../../components/common/NovoAddressAutocomplete";
import NovoTextField from "../../../../components/common/NovoTextField";
import { NovoTooltip } from "../../../../components/common/NovoTooltip";
import usePutMetric from "../../../../hooks/usePutMetric";
import { Steps } from "../../../../types/cockpit/types";
import { isValidEmail } from "../../../auth/emailValidator";
import ProgressBar from "../ProgressBar";
import { StatusQuoScreenProps } from "./StatusQuoFunnel";

export default function Screen6({ schnellcheck, setSchnellcheck, isUpdateRunning, prev, next }: StatusQuoScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [searchParams] = useSearchParams();
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [telephoneError, setTelephoneError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [autocompleteAddress, setAutocompleteAddress] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [addressError, setAddressError] = useState<string>();
  const location = useLocation(); // To get the current pathname

  const [putMetric] = usePutMetric();

  const updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          firstName: event.target.value,
        },
      },
    });
  };

  const updateLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          lastName: event.target.value,
        },
      },
    });
  };

  const updateTelephone = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          telephone: event.target.value,
        },
      },
    });
  };

  const updateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          email: event.target.value,
        },
      },
    });
  };

  const updateAddress = (address: google.maps.places.AutocompletePrediction | null) => {
    setSchnellcheck({
      ...schnellcheck,
      answers: {
        ...schnellcheck.answers,
        contact: {
          ...schnellcheck.answers?.contact,
          address: address?.description,
        },
      },
    });
    setAutocompleteAddress(address);
  };

  const validate = () => {
    const { email, telephone, firstName, lastName, address } = schnellcheck.answers?.contact ?? {};
    if (!firstName) {
      setFirstNameError("Pflichtfeld");
      return false;
    } else {
      setFirstNameError(undefined);
    }

    if (!lastName) {
      setLastNameError("Pflichtfeld");
      return false;
    } else {
      setLastNameError(undefined);
    }

    if (!telephone) {
      setTelephoneError("Pflichtfeld");
      return false;
    } else if (!/(^0|^\+49)\s*[1-9][0-9]{2,3}\s*[0-9]{0,3}\s*[0-9]{0,4}$/g.test(telephone)) {
      setTelephoneError("Ungültige Handynummer");
      return false;
    } else {
      setTelephoneError(undefined);
    }

    if (!email) {
      setEmailError("Pflichtfeld");
      return false;
    } else if (!isValidEmail(email)) {
      setEmailError("Ungültige Email Adresse");
      return false;
    } else {
      setEmailError(undefined);
    }

    if (!address) {
      setAddressError("Pflichtfeld");
      return false;
    } else if (!includesHouseNumber(autocompleteAddress)) {
      setAddressError("Geben Sie die Hausnummer an");
      return false;
    } else {
      setAddressError(undefined);
    }

    const tenant = searchParams.get("tenant");
    const pathWithTenant = `${location.pathname}${tenant ? `?tenant=${tenant}` : ""}${Steps.offerRequest}`;
    putMetric(pathWithTenant, "OfferRequested");
    return true;
  };

  const validateAndSave = () => {
    if (validate()) {
      next();
    }
  };

  return (
    <Box
      sx={{
        mx: { xs: 0, lg: 10 },
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        rowSpacing={{
          xs: 0,
          lg: 4,
        }}
        columnSpacing={{
          xs: 0,
          lg: 8,
        }}
      >
        <Grid size={12}>
          <ProgressBar label={"100% geschafft"} value={98} mt={{ xs: 2, lg: 0 }} />
        </Grid>
        <Grid size={12}>
          <Stack maxWidth={700} justifySelf={"center"} mx={2}>
            <Typography
              variant='h5'
              sx={{
                fontWeight: "bold",
                mb: 2,
              }}
            >
              Anfrage Angebot & Erstgespräch
            </Typography>
            <Typography variant='body1' fontSize={isMobile ? 14 : 16} mb={2}>
              Fordern Sie ihr persönliches Angebot an und vereinbaren Sie ein kostenloses Erstgespräch mit unserem Team.
            </Typography>
            <FormControl sx={{ mb: 2 }}>
              <NovoTextField
                variant='outlined'
                id='first-name-input'
                data-cy='first-name-input'
                value={schnellcheck.answers?.contact?.firstName ?? ""}
                type='text'
                label='Vorname'
                onChange={updateFirstName}
                required={true}
                error={!!firstNameError}
                helperText={firstNameError}
                slotProps={{
                  inputLabel: { shrink: !!schnellcheck.answers?.contact?.firstName },
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2 }}>
              <NovoTextField
                variant='outlined'
                id='last-name-input'
                data-cy='last-name-input'
                value={schnellcheck.answers?.contact?.lastName ?? ""}
                type='text'
                label='Nachname'
                onChange={updateLastName}
                required={true}
                error={!!lastNameError}
                helperText={lastNameError}
                slotProps={{
                  inputLabel: { shrink: !!schnellcheck.answers?.contact?.lastName },
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2 }}>
              <NovoTextField
                variant='outlined'
                id='telephone-input'
                data-cy='telephone-input'
                value={schnellcheck.answers?.contact?.telephone ?? ""}
                type='tel'
                inputMode='tel'
                label='Handynummer'
                onChange={updateTelephone}
                required={true}
                error={!!telephoneError}
                helperText={telephoneError}
                slotProps={{
                  inputLabel: { shrink: !!schnellcheck.answers?.contact?.telephone },
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2 }}>
              <NovoTextField
                variant='outlined'
                id='email-input'
                data-cy='email-input'
                value={schnellcheck.answers?.contact?.email ?? ""}
                type='email'
                inputMode='email'
                label='Email Adresse'
                onChange={updateEmail}
                required={true}
                error={!!emailError}
                helperText={emailError}
                slotProps={{
                  inputLabel: { shrink: !!schnellcheck.answers?.contact?.email },
                }}
              />
            </FormControl>
            <Stack direction='row' alignItems='center' spacing={2} sx={{ mb: 2 }}>
              <NovoAddressAutocomplete
                id='address-input'
                data_cy='address-input'
                address={autocompleteAddress}
                updateAddress={updateAddress}
                addressError={addressError}
              />
              <NovoTooltip title='Wenn Ihre Adresse in den Vorschlägen nicht enthalten ist, geben Sie weitere Informationen ein, beispielsweise Stadt oder Stadtteil.'>
                <HelpIcon sx={{ fontSize: { xs: 18, lg: 20 } }} />
              </NovoTooltip>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent='space-between'
              sx={{
                mt: {
                  xs: 2,
                  lg: 4,
                },
                px: 3,
                mb: {
                  xs: 3,
                  lg: 0,
                },
              }}
            >
              <Button variant='text' color='secondary' onClick={prev}>
                Zurück
              </Button>
              <ButtonWithSpinner
                variant='contained'
                loading={isUpdateRunning}
                data-cy='submit-offer-request-btn'
                onClick={validateAndSave}
                label={"Angebot anfordern"}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
