import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Alert, IconButton, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useDownloadIsfpDocumentsZip } from "../../../hooks/useDownloadIsfpDocumentsZip";
import { IsfpProcessStatus } from "../../../types/cockpit/types";
import IsfpAcceptOffer from "./processes/IsfpAcceptOffer";
import IsfpFinished from "./processes/IsfpFinished";
import IsfpInProgress from "./processes/IsfpInProgress";
import IsfpInvited from "./processes/IsfpInvited";
import IsfpOfferAccepted from "./processes/IsfpOfferAccepted";
import IsfpOfferRejected from "./processes/IsfpOfferRejected";
import IsfpRenovationAnswered from "./processes/IsfpRenovationAnswered";
import IsfpSchnellcheckInProgress from "./processes/IsfpSchnellcheckInProgress";
import IsfpSchnellcheckReady from "./processes/IsfpSchnellcheckReady";
import { RowDetailProps } from "./processes/types";
import IsfpRenovationInProgress from "./processes/IsfpRenovationInProgress";

/**
 * Detailed workflow view for iSFP documents
 */
export default function IsfpProcessDetails({ row, onChange }: RowDetailProps) {
  const [downloadButtonColor, setDownloadButtonColor] = useState<"primary" | "error" | "disabled" | "success">("primary");
  const currentStatusOrder = IsfpProcessStatus.OrderedStatuses[row.status];
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { downloadIsfpDocumentsZip, downloadIsfpDocumentsZipError, downloadIsfpDocumentsZipRunning, downloadIsfpDocumentsZipLink } =
    useDownloadIsfpDocumentsZip();

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorMessage(false);
  };

  useEffect(() => {
    if (downloadIsfpDocumentsZipLink) {
      window.location.href = downloadIsfpDocumentsZipLink.url;
      setDownloadButtonColor("success");
    }
    if (downloadIsfpDocumentsZipError) {
      setShowErrorMessage(true);
      setDownloadButtonColor("error");
    }
    if (downloadIsfpDocumentsZipRunning) {
      setDownloadButtonColor("disabled");
    }
  }, [downloadIsfpDocumentsZipLink, downloadIsfpDocumentsZipError, downloadIsfpDocumentsZipRunning]);

  return (
    <Grid container sx={{ flexGrow: 1, m: 4 }}>
      <Grid size={11}>
        <Stack>
          <Step isDone={true} label={IsfpProcessStatus.toString(IsfpProcessStatus.INVITE_SENT)}>
            <IsfpInvited row={row} />
          </Step>
          <Step
            isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.SCHNELLCHECK_STARTED]}
            label={IsfpProcessStatus.toString(IsfpProcessStatus.SCHNELLCHECK_STARTED)}
          >
            <IsfpSchnellcheckInProgress row={row} />
          </Step>
          <Step
            isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.SCHNELLCHECK_FINISHED]}
            label={IsfpProcessStatus.toString(IsfpProcessStatus.SCHNELLCHECK_FINISHED)}
          >
            <IsfpSchnellcheckReady row={row} onChange={onChange} />
          </Step>
          <Step
            isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.OFFER_REQUESTED]}
            label={IsfpProcessStatus.toString(IsfpProcessStatus.OFFER_REQUESTED)}
          >
            <IsfpAcceptOffer row={row} onChange={onChange} />
          </Step>
          {/* hide further steps in timeline if offer for iSFP was already rejected by lead */}
          {row.status != IsfpProcessStatus.OFFER_REJECTED && (
            <>
              <Step
                isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.OFFER_ACCEPTED]}
                label={IsfpProcessStatus.toString(IsfpProcessStatus.OFFER_ACCEPTED)}
              >
                <IsfpOfferAccepted row={row} />
              </Step>
              <Step
                isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED]}
                label={IsfpProcessStatus.toString(IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED)}
              >
                <IsfpRenovationInProgress row={row} />
              </Step>
              <Step
                isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED]}
                label={IsfpProcessStatus.toString(IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED)}
              >
                <IsfpRenovationAnswered row={row} />
              </Step>
              <Step
                isDone={currentStatusOrder >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED]}
                label={IsfpProcessStatus.toString(IsfpProcessStatus.PREPARING_ISFP_DOCUMENT)}
              >
                <IsfpInProgress row={row} onChange={onChange} />
              </Step>
              <Step isDone={row.status == IsfpProcessStatus.FINISHED} label={IsfpProcessStatus.toString(IsfpProcessStatus.FINISHED)}>
                <IsfpFinished row={row} />
              </Step>
            </>
          )}
          {row.status == IsfpProcessStatus.OFFER_REJECTED && (
            <Step isDone label={IsfpProcessStatus.toString(IsfpProcessStatus.OFFER_REJECTED)}>
              <IsfpOfferRejected row={row} />
            </Step>
          )}
        </Stack>
      </Grid>
      <Grid size={1}>
        <Tooltip title='Alle Lead-Informationen herunterladen'>
          <IconButton disabled={downloadIsfpDocumentsZipRunning} onClick={() => downloadIsfpDocumentsZip(row.id)}>
            <FileDownloadIcon color={downloadButtonColor} />
          </IconButton>
        </Tooltip>
        <Snackbar open={showErrorMessage} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
            Es ist leider ein Fehler aufgetreten. Bitten kontaktiere uns.
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

interface StepProps {
  isDone: boolean;
  label: string;
  children?: React.ReactNode;
}

const Step = ({ isDone, label, children }: StepProps) => {
  return (
    <Grid container padding={0}>
      <Grid size={{ xs: 3 }}>
        <Milestone label={label} isDone={isDone} />
      </Grid>
      <Grid size={{ xs: 9 }} alignContent={"center"} padding={3}>
        {isDone && children}
      </Grid>
    </Grid>
  );
};

function Milestone({ label, isDone }: { label: string; isDone: boolean }) {
  return (
    <Typography
      sx={{
        height: "100%",
        borderLeftColor: isDone ? "primary.main" : "#E2E7E7",
        borderLeftWidth: isDone ? "0.2rem" : "0.1rem",
        borderLeftStyle: "solid",
        pl: "1rem",
        alignContent: "center",
        ml: isDone ? 0 : "0.05rem",
        fontSize: 12,
        fontWeight: isDone ? "fontWeightBold" : "fontWeightRegular",
        "&:before": {
          content: '""',
          display: "inline-block",
          width: 15,
          height: 15,
          borderRadius: 8,
          bgcolor: isDone ? "primary.main" : "#FBFBFB",
          border: isDone ? "" : "0.15rem solid #C6C7C8",
          position: "relative",
          left: isDone ? -25 : -24,
          verticalAlign: "middle",
        },
      }}
    >
      {label}
    </Typography>
  );
}
