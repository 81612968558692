import LocationOnIcon from "@mui/icons-material/LocationOn";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { Box, BoxProps, Chip, IconButton, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { formatDate } from "date-fns";
import { useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../../../../Contexts";
import { useFetchLead } from "../../../../../../hooks/useFetchLead";
import { BubbleFlow, IsfpProcessStatus, IsfpProperty, Lead } from "../../../../../../types/cockpit/types";
import { RenovationQuestionnaire } from "../../../../../../types/RenovationQuestionnaire";
import { SchnellcheckQuestionnaire } from "../../../../../../types/Schnellcheck";
import { isAdmin } from "../../../../../../utils/auth";
import Loading from "../../../../Loading";
import { ConsentNotGiven } from "../../Consent";
import { EXAMPLE_LEAD_ID_PREFIX, usePlaceholderLeadData } from "../../PlaceholderLeadData";
import OpenIsfp from "./OpenIsfp";
import RenovationsAnswers from "./renovations/RenovationsAnswers";
import StatusQuoAnswers from "./status-quo/StatusQuoAnswers";

export default function IsfpDetails() {
  const { leadId } = useParams();
  const { getLead, lead, isLoading } = useFetchLead();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sampleLead = usePlaceholderLeadData()[0];

  const searchFilter = decodeURIComponent(searchParams.get("filter") ?? "");

  const isfpData = isfpState(lead || sampleLead);

  useEffect(() => {
    if (!leadId) return;
    if (leadId.startsWith(EXAMPLE_LEAD_ID_PREFIX)) return;
    getLead(leadId);
  }, [getLead, leadId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: "56px",
        width: "calc(100% - 56px)",
      }}
    >
      <Grid
        container
        spacing={2}
        columnSpacing={{
          xs: 0,
          lg: 4,
        }}
        sx={{
          mt: 2,
          maxWidth: "xl",
          mx: { xs: 0, xl: "auto" },
          backgroundColor: "#FFFFFF",
          padding: 4,
        }}
      >
        <Grid size={1}>
          <IconButton
            onClick={() => {
              navigate(`/cockpit/leads?filter=${encodeURIComponent(searchFilter)}&selected=${leadId}`);
            }}
          >
            <WestOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid size={11}>
          <Typography variant='h4'>iSFP Timeline</Typography>
        </Grid>
        <Grid size={12}>
          <Table sx={{ "& .MuiTableCell-root": { border: "none", backgroundColor: "#FBFBFB" } }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Adresse</TableCell>
                <TableCell>E-Mail</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component='th' scope='row' sx={{ fontWeight: "fontWeightBold" }}>
                  {lead?.name || sampleLead.name}
                </TableCell>
                <TableCell sx={{ fontWeight: "fontWeightBold" }}>
                  <LocationOnIcon sx={{ pr: "0.5rem", verticalAlign: "middle", fontSize: "2rem", color: "primary.main" }} />
                  {isfpData?.address}
                </TableCell>
                <TableCell sx={{ fontWeight: "fontWeightBold" }}>{lead?.email || sampleLead.email}</TableCell>
                <TableCell sx={{ fontWeight: "fontWeightBold" }}>
                  {isfpData && isfpData.status && isfpData.status !== IsfpProcessStatus.NOT_STARTED && (
                    <Chip sx={{ mr: 1 }} label={IsfpProcessStatus.toString(isfpData.status)} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid size={12}>
          <Timeline lead={lead || sampleLead} isfpData={isfpData} />
        </Grid>
      </Grid>
    </Box>
  );
}

interface TimelineComponentProps extends BoxProps {
  achieved: boolean;
}
const Milestone = styled(Box)<TimelineComponentProps>(({ achieved }) => ({
  border: `5px solid ${achieved ? "#00A8E6" : "#ACD3E2"}`,
  borderRadius: "12px",
  height: "24px",
  width: "24px",
  minWidth: "24px",
}));

const Line = styled(Box)<TimelineComponentProps>(({ achieved }) => ({
  border: `2px solid ${achieved ? "#00A8E6" : "#ACD3E2"}`,
  float: "right",
  marginRight: "10px",
  height: "100%",
  width: "0px",
}));

const Filler = styled(Box)(() => ({
  height: "90px",
  width: "0px",
}));

function Timeline({ lead, isfpData }: { lead?: Lead; isfpData?: IsfpCompletenessState }) {
  if (!lead || !isfpData) {
    return <></>;
  }
  const consultantDataReviewConsent = lead.consultantDataReviewConsent === undefined || lead.consultantDataReviewConsent; //NOVO-533
  return (
    <Grid container columnSpacing={2}>
      {IsfpProcessStatus.all().map((state) => (
        <TimelineEntry key={state} state={state} isfpData={isfpData} dataReviewConsent={consultantDataReviewConsent} />
      ))}
    </Grid>
  );
}

function TimelineEntry({ state, isfpData, dataReviewConsent }: { state: IsfpProcessStatus; isfpData: IsfpCompletenessState; dataReviewConsent?: boolean }) {
  const { user } = useContext(AuthContext);

  if (isfpData.status === undefined) {
    return null;
  }

  const achieved = IsfpProcessStatus.OrderedStatuses[state] <= IsfpProcessStatus.OrderedStatuses[isfpData.status];
  if (isfpData.status == IsfpProcessStatus.OFFER_REJECTED) {
    // offer rejected (for now) is a final state, so everything after this state can be ignored
    if (!achieved) {
      // Ignore future non-happy path states
      return null;
    }
  } else {
    // if the isfp status is not rejected yet, we only hide the rejected (unhappy) path
    if (state == IsfpProcessStatus.OFFER_REJECTED) {
      return null;
    }
  }

  const printTimestamp = (): string => {
    if (!achieved) return "TBD";
    switch (state) {
      case IsfpProcessStatus.INVITE_SENT:
        return isfpData.isfp?.eventTimestamps?.invitedOn ? formatDate(isfpData.isfp?.eventTimestamps?.invitedOn, "dd MMM") : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.SCHNELLCHECK_STARTED:
        return isfpData.isfp?.eventTimestamps?.schnellcheckStartedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.schnellcheckStartedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.SCHNELLCHECK_FINISHED:
        return isfpData.isfp?.eventTimestamps?.schnellcheckFinishedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.schnellcheckFinishedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.OFFER_REQUESTED:
        return isfpData.isfp?.eventTimestamps?.offerRequestedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.offerRequestedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.OFFER_ACCEPTED:
        return isfpData.isfp?.eventTimestamps?.offerAcceptOrRejectOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.offerAcceptOrRejectOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.OFFER_REJECTED:
        return isfpData.isfp?.eventTimestamps?.offerAcceptOrRejectOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.offerAcceptOrRejectOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED:
        return isfpData.isfp?.eventTimestamps?.renovationQuestionaireStartedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.renovationQuestionaireStartedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED:
        return isfpData.isfp?.eventTimestamps?.renovationQuestionaireFinishedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.renovationQuestionaireFinishedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      case IsfpProcessStatus.FINISHED:
        return isfpData.isfp?.eventTimestamps?.isfpDocumentFinishedOn
          ? formatDate(isfpData.isfp?.eventTimestamps?.isfpDocumentFinishedOn, "dd MMM")
          : "Zeitstempel nicht verfügbar";
      default:
        return "Zeitstempel nicht verfügbar";
    }
  };

  const details = (achieved: boolean) => {
    if (achieved && state === IsfpProcessStatus.SCHNELLCHECK_FINISHED) {
      if (!dataReviewConsent && !isAdmin(user)) {
        return <ConsentNotGiven p={4} />;
      }
      return <StatusQuoAnswers schnellcheck={isfpData.schnellcheck} bubbleFlow={isfpData.bubbleFlow} />;
    }
    if (achieved && state === IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_FINISHED && isfpData.renovations) {
      if (!dataReviewConsent && !isAdmin(user)) {
        return <ConsentNotGiven p={4} />;
      }
      return <RenovationsAnswers renovations={isfpData.renovations} />;
    }
    if (achieved && state === IsfpProcessStatus.FINISHED) {
      if (!dataReviewConsent && !isAdmin(user)) {
        return <ConsentNotGiven p={4} />;
      }
      return <OpenIsfp id={isfpData.isfp?.id} disabled={!achieved} />;
    }
    return <Filler />;
  };
  return (
    <>
      <Grid size={2}>
        <Stack direction='row' justifyContent='end'>
          <Stack mr={1} sx={{ overflowX: "hidden" }}>
            <Typography variant='body1' sx={{ fontSize: 14, fontWeight: achieved ? 800 : 400, textAlign: "right" }}>
              {IsfpProcessStatus.toString(state)}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: 13, color: achieved ? "#625B71FF" : "#625B7188", textAlign: "right" }}>
              {printTimestamp()}
            </Typography>
          </Stack>
          <Milestone achieved={achieved} alignSelf='center' />
        </Stack>
      </Grid>
      <Grid size={10}>
        <></>
      </Grid>
      <Grid size={2} alignContent='end'>
        <Line achieved={achieved} />
      </Grid>
      <Grid size={10}>{details(achieved)}</Grid>
    </>
  );
}

interface IsfpCompletenessState {
  address: string;
  isfp?: IsfpProperty;
  schnellcheck?: SchnellcheckQuestionnaire;
  bubbleFlow?: BubbleFlow;
  renovations?: RenovationQuestionnaire;
  status?: IsfpProcessStatus;
}

const isfpState = (lead: Lead | undefined): IsfpCompletenessState | undefined => {
  if (!lead) return;
  const isfp = lead.properties?.find((property) => !!property.isfp)?.isfp;
  const schnellcheck = isfp?.schnellcheck;
  const renovations = isfp?.renovationQuestionnaire;
  const bubbleFlow = isfp?.bubbleFlow;

  return {
    address: schnellcheck?.answers.contact?.address ?? bubbleFlow?.statusQuoResponse?.address ?? "",
    status: isfp?.status,
    schnellcheck,
    renovations,
    bubbleFlow,
    isfp,
  };
};
