import { Box, Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { useFetchIsfpDocumentUrl } from "../../../../hooks/useFetchIsfpDocument";
import { useEffect } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { formatDate } from "date-fns";

export default function IsfpFinished({ row }: RowDetailProps) {
  const { fetchIsfpDocumentUrl, isfpDocumentLink, fetchDocumentRunning } = useFetchIsfpDocumentUrl();

  const openPdfDoc = () => {
    fetchIsfpDocumentUrl(row.id);
  };

  useEffect(() => {
    if (isfpDocumentLink) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.href = isfpDocumentLink.url;
      link.click();
    }
  }, [isfpDocumentLink]);

  return (
    <Typography>
      iSFP Dokument wurde
      {row.eventTimestamps?.isfpDocumentFinishedOn ? (
        <>
          {" "}
          am <i>{formatDate(row.eventTimestamps?.isfpDocumentFinishedOn, "dd.MM.yyyy")} </i>
        </>
      ) : (
        " "
      )}
      angefertigt
      <Box display={"inline"} pl={2}>
        <ButtonWithSpinner variant='outlined' color='secondary' loading={fetchDocumentRunning} label='Dokument herunterladen' onClick={openPdfDoc} />
      </Box>
    </Typography>
  );
}
