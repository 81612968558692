import LaunchIcon from "@mui/icons-material/Launch";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { DisplayBubbleFlowRenovationDialog } from "../../leads/leadlist/DisplayBubbleFlowRenovationDialog";
import { RowDetailProps } from "./types";

export default function IsfpRenovationAnswered({ row }: RowDetailProps) {
  const { getLead, lead } = useFetchLead();
  const [isBubbleSchnellCheckOpen, setBubbleSchnellCheckOpen] = useState(false);

  const toggleBubbleSchnellCheckModal = () => {
    setBubbleSchnellCheckOpen(!isBubbleSchnellCheckOpen);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    setBubbleSchnellCheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <>
      <Box>
        <Typography>
          iSFP-Fragebogen wurde
          {row.eventTimestamps?.renovationQuestionaireFinishedOn ? (
            <>
              {" "}
              am <i>{formatDate(row.eventTimestamps?.renovationQuestionaireFinishedOn, "dd.MM.yyyy")} </i>
            </>
          ) : (
            " "
          )}
          ausgefüllt.
          <Box display={"inline"} pl={2}>
            <Link
              component='button'
              underline={"always"}
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
              onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
            >
              <LaunchIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              {"Antworten ansehen"}
            </Link>
          </Box>
        </Typography>
      </Box>
      <DisplayBubbleFlowRenovationDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleSchnellCheckOpen}
        handleClose={toggleBubbleSchnellCheckModal}
      />
    </>
  );
}
