import { Status } from "./Schnellcheck";

export enum DeploymentEnv {
  Dev = "dev",
  Stg = "stg",
  Prod = "prod",
  Local = "local",
  Embedded = "embedded",
}

export enum UserRoles {
  admin = "admin",
  consultant = "consultant",
  bankManager = "bank-manager",
}

export type TenantBasicInfo = {
  id: string;
  name: string;
  displayName: string;
  emailDomain: string;
};

export type TourStatus = "FINISHED" | "SKIPPED" | "FAILED";

export type GuidedToursState = {
  cockpit?: {
    status: TourStatus;
    step: number;
    timestamp: number;
  };
};

export type User = {
  id: string;
  name: string;
  email: string;
  referrerCode: string;
  shortLink?: string;
  groups?: UserRoles[];
  tenant?: TenantBasicInfo;
  guidedTours?: GuidedToursState;
};

export enum Product {
  onePager = "onePager",
  isfp = "isfp",
  consumptionCertificate = "consumptionCertificate",
}

export type PropertyInput = {
  type: string;
  address: string;
  area: number;
  yearOfConstruction: number;
  isMonument: boolean;
  heatingSystemType: string;
};

export type Property = {
  id: string;
  property: PropertyInput;
  analysis: PropertyAnalysis;
  ownerEmail: string;
  createdAt: number;
};

export type KfwPropertyReform = {
  consumption: EnergyConsumption;
  status: Status;
  costs: KfwRenovationCosts;
};

export type SanierungspflichtPropertyReform = {
  consumption: EnergyConsumption;
  status: Status;
  costs: SanierungspflichtRenovationCosts;
};

export type PropertyAnalysis = {
  kfw85ee: KfwPropertyReform;
  sanierungspflicht: SanierungspflichtPropertyReform;
  consumption: EnergyConsumption;
  status: Status;
};

export type EnergyConsumption = {
  energyClass: string;
  cO2Emission: number;
  trees: number;
  primaryEnergy: number;
  finalEnergy: number;
  energyRequirement: number;
  energyConsumption: number;
  costPerYear: number;
};

export type KfwRenovationCosts = {
  buildingEnvelopeCosts: number;
  airWaterHeatPumpCosts: number;
  heatingOptimizationCosts: number;
  pVSystemCosts: number;
  ventilationSystemCosts: number;
  planningCosts: number;
  totalCost: number;
  subsidy: number;
};

export type SanierungspflichtRenovationCosts = {
  buildingEnvelopeCosts: number;
  airWaterHeatPumpCosts: number;
  heatingOptimizationCosts: number;
  planningCosts: number;
  totalCost: number;
  subsidy: number;
};

export enum Themes {
  NOVO = "novo",
  NOVO_DARK = "novoDark",
  DZ = "dz",
  PSD = "psd",
  VB = "vb",
  BSH = "bsh",
  COBA = "coba",
  HAUSBANK = "hausbank",
}
