import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useContext, useState } from "react";
import { Product } from "../../../../../types/cockpit/types";
import { ConsentNotGiven } from "../Consent";
import { InviteLeadToProductDialog } from "../InviteLeadToProductDialog";
import { RowProps } from "../LeadList";
import CCWorkflow from "./CCWorkflow";
import IsfpWorkflow from "./IsfpWorkflow";
import OPWorkflow from "./OPWorkflow";
import { AuthContext } from "../../../../../Contexts";
import { isAdmin } from "../../../../../utils/auth";

export default function LeadWorkflow({ row }: { row: RowProps }) {
  const { user } = useContext(AuthContext);
  const [inviteLeadToProductOpen, setInviteLeadToProductOpen] = useState(false);
  const [leadToProductInvite, setLeadToProductInvite] = useState<{ leadId: string; leadName: string; leadEmail: string; product: Product }>();

  const toggleInviteLeadToProductModal = () => {
    setInviteLeadToProductOpen(!inviteLeadToProductOpen);
  };

  const openInviteLeadModal = (product: Product) => {
    setLeadToProductInvite({ leadId: row.id, leadName: row.name, leadEmail: row.email, product });
    setInviteLeadToProductOpen(true);
  };

  return (
    <Box display='flex' flexDirection='row' sx={{ flexGrow: 1, my: 4, ml: 4 }}>
      <Box flexGrow={1}>
        <Stack spacing={4} divider={<Divider orientation='horizontal' />}>
          <IsfpWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
          <CCWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
          <OPWorkflow row={row} openInviteLeadModal={openInviteLeadModal} />
        </Stack>
      </Box>
      <Box display={"flex"} flexDirection={"row"} pl={3} pr={1} maxWidth={160}>
        {!row.consultantDataReviewConsent && !isAdmin(user) && <ConsentNotGiven fontSize='0.7rem' />}
      </Box>
      {inviteLeadToProductOpen && <InviteLeadToProductDialog onClose={toggleInviteLeadToProductModal} invite={leadToProductInvite!} />}
    </Box>
  );
}
