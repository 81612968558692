import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import { Button, Grid, MobileStepper, ToggleButton as MuiToggleButton, TextField, Tooltip, styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FunctionComponent, useEffect, useState, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import basement from "../../imgs/report_basement.png";
import envelope from "../../imgs/report_envelope.png";
import roof from "../../imgs/report_roof.png";
import { StatusItem } from "../one-pager/StatusItem";
import { HOUSE_INSULATION } from "./storage";
import useUpsertQuestionnaire from "../../hooks/useUpsertQuestionnaire";
import { HouseInsulation } from "../../types/Schnellcheck";

const SELECTED_INSULATIONS_LS_KEY = "selected_insulations";

type InsulationProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const Insulation: FunctionComponent<InsulationProps> = ({ question, setNextQuestion }) => {
  const [upsertQuestionnaire] = useUpsertQuestionnaire();
  const [selectedInsulations, setSelectedInsulations] = useState<string[]>([]);
  const [insulationState, setInsulation] = useState<HouseInsulation>({});

  const intl = useIntl();

  function toggleChange(_: React.MouseEvent, value: string) {
    let actualSelection;

    if (selectedInsulations.includes(value)) {
      actualSelection = selectedInsulations.filter((obj) => obj !== value);
    } else {
      actualSelection = [...selectedInsulations, value];
    }
    setSelectedInsulations(actualSelection);
    localStorage.setItem(SELECTED_INSULATIONS_LS_KEY, JSON.stringify(actualSelection));
  }

  function isSelected(value: string) {
    return selectedInsulations.includes(value);
  }

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(HOUSE_INSULATION);
    if (answer) {
      const parsedAnswer: HouseInsulation = JSON.parse(answer);
      setInsulation(parsedAnswer);
    }
    const lsSelecInsulations = localStorage.getItem(SELECTED_INSULATIONS_LS_KEY);
    if (lsSelecInsulations) {
      const parsedSelected: string[] = JSON.parse(lsSelecInsulations);
      setSelectedInsulations(parsedSelected);
    }
  }, []);

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#106bb5",
    },
  });

  function yearOfWallsInsulationOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newInsulationState = {
      ...insulationState,
      yearOfWallsInsulation: e.target.value ? parseInt(e.target.value) : undefined,
      yearOfBasementInsulation: insulationState.yearOfBasementInsulation,
      yearOfRoofInsulation: insulationState.yearOfRoofInsulation,
    };
    setInsulation(newInsulationState);
    localStorage.setItem(HOUSE_INSULATION, JSON.stringify(newInsulationState));
  }

  function yearOfBasementInsulationOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newInsulationState = {
      ...insulationState,
      yearOfBasementInsulation: e.target.value ? parseInt(e.target.value) : undefined,
      yearOfWallsInsulation: insulationState.yearOfWallsInsulation,
      yearOfRoofInsulation: insulationState.yearOfRoofInsulation,
    };
    setInsulation(newInsulationState);
    localStorage.setItem(HOUSE_INSULATION, JSON.stringify(newInsulationState));
  }

  function yearOfRoofInsulationOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newInsulationState = {
      ...insulationState,
      yearOfRoofInsulation: e.target.value ? parseInt(e.target.value) : undefined,
      yearOfBasementInsulation: insulationState.yearOfBasementInsulation,
      yearOfWallsInsulation: insulationState.yearOfWallsInsulation,
    };
    setInsulation(newInsulationState);
    localStorage.setItem(HOUSE_INSULATION, JSON.stringify(newInsulationState));
  }

  function sendQuestionnaireOnClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    upsertQuestionnaire();
    setNextQuestion(3);
  }

  return (
    <>
      <Stack spacing={2} alignItems='center' style={{ width: "100%" }} p={3}>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Typography component='h2' style={{ fontSize: 20, fontWeight: 600 }}>
            <FormattedMessage id='app.insulation.title' />
          </Typography>
          <Tooltip title='Bitte gib an, welche Bereiche bereits beim Bau des Hauses oder auch nachträglich gedämmt wurden!' style={{ paddingLeft: 9 }}>
            <HelpIcon fontSize='inherit' />
          </Tooltip>
        </Stack>

        <Grid container spacing={2} textAlign='center'>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2} alignItems='center'>
              <ToggleButton key={"walls"} value={"walls"} data-cy='walls-option' onChange={toggleChange} selected={isSelected("walls")}>
                <StatusItem logo={envelope} label={intl.formatMessage({ id: "app.insulation.walls" })} />
              </ToggleButton>
              {isSelected("walls") && (
                <TextField
                  size='small'
                  id='yearOfWallsInsulation'
                  label='Jahr (circa)'
                  variant='outlined'
                  value={insulationState?.yearOfWallsInsulation}
                  onChange={yearOfWallsInsulationOnChange}
                  fullWidth
                  data-cy='walls-year-input'
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2} alignItems='center'>
              <ToggleButton key={"basement"} value={"basement"} data-cy='basement-option' onChange={toggleChange} selected={isSelected("basement")}>
                <StatusItem logo={basement} label={intl.formatMessage({ id: "app.insulation.basement" })} />
              </ToggleButton>
              {isSelected("basement") && (
                <TextField
                  size='small'
                  id='yearOfBasementInsulation'
                  label='Jahr (circa)'
                  variant='outlined'
                  value={insulationState?.yearOfBasementInsulation}
                  onChange={yearOfBasementInsulationOnChange}
                  fullWidth
                  data-cy='basement-year-input'
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack spacing={2} alignItems='center'>
              <ToggleButton key={"roof"} value={"roof"} data-cy='roof-option' onChange={toggleChange} selected={isSelected("roof")}>
                <StatusItem logo={roof} label={intl.formatMessage({ id: "app.insulation.roof" })} />
              </ToggleButton>
              {isSelected("roof") && (
                <TextField
                  size='small'
                  id='yearOfRoofInsulation'
                  label='Jahr (circa)'
                  variant='outlined'
                  value={insulationState?.yearOfRoofInsulation}
                  onChange={yearOfRoofInsulationOnChange}
                  fullWidth
                  data-cy='roof-year-input'
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <MobileStepper
          variant='dots'
          steps={6}
          activeStep={question}
          position='top'
          sx={{ flexGrow: 1, position: "relative", alignItems: "center", width: "100%" }}
          nextButton={
            <Button variant='contained' size='small' data-cy='insulation-next-btn' onClick={(e) => sendQuestionnaireOnClick(e)} disabled={question === 5}>
              <FormattedMessage id='app.insulation.next' /> <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button variant='contained' size='small' onClick={() => setNextQuestion(1)} disabled={question === 0}>
              <KeyboardArrowLeft /> <FormattedMessage id='app.insulation.back' />
            </Button>
          }
        />
      </Stack>
    </>
  );
};
