import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import useImage from "../../../../hooks/useImage";
import useUpsertSchnellcheck from "../../../../hooks/useUpsertSchnellcheck";
import useResumeSchnellcheck from "../../../../hooks/useResumeSchnellcheck";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Screen4 from "./Screen4";
import Screen5 from "./Screen5";
import { ConfirmationScreen } from "./ConfirmationScreen";
import { AnalysisReport, SchnellcheckSource, UpsertQuestionnaireRequest } from "../../../../types/Schnellcheck";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Smartlook from "smartlook-client";
import { Steps } from "../../../../types/cockpit/types";
import { fromHash } from "./utils";
import ConsentSnackbar from "../Consent";
import usePutMetric from "../../../../hooks/usePutMetric";
import { ScreenProps } from "../FormScreen";
import IntroductionScreen from "./IntroductionScreen";
import { retrieveRum } from "../../../../AwsRum";
import Screen6 from "./Screen6";

export type StatusQuoScreenProps = ScreenProps & {
  schnellcheck: UpsertQuestionnaireRequest;
  setSchnellcheck: Dispatch<SetStateAction<UpsertQuestionnaireRequest>>;
};

export default function StatusQuoFunnel({ embedded = false, renderIntroduction = false }: { embedded?: boolean; renderIntroduction?: boolean }) {
  if (embedded) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <CssBaseline />
        <StatusQuo embedded={embedded} renderIntroduction={renderIntroduction} />
        <ConsentSnackbar smartlookId='794360b37db241e21c97fcde3436bc393f90a19d' metricName='Schnellcheck' />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <StatusQuo renderIntroduction={renderIntroduction} />
      <ConsentSnackbar smartlookId='794360b37db241e21c97fcde3436bc393f90a19d' metricName='Schnellcheck' />
    </Box>
  );
}

function StatusQuo({ embedded = false, renderIntroduction = false }: { embedded?: boolean; renderIntroduction?: boolean }) {
  const { referrerCode } = useParams();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [putMetric] = usePutMetric();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const leadId = searchParams.get("leadId") ?? undefined;
  const referrer = searchParams.get("referrer") ?? undefined;
  const tenantName = searchParams.get("tenant") ?? undefined;
  const [schnellcheck, setSchnellcheck] = useState<UpsertQuestionnaireRequest>({
    leadId,
    source: SchnellcheckSource.standalone, // TODO: use the embedded flag to conditionally set the source, only after the FoerderWelt flow has been merged with the standard Schnellcheck
    referrerCode,
    referrer,
    tenantName,
  });
  const [report, setReport] = useState<AnalysisReport | undefined>();
  const { upsertSchnellcheck, upsertSchnellcheckRunning, upsertSchnellcheckResponse } = useUpsertSchnellcheck();
  const { resumeSchnellcheck, toLocal, resuming } = useResumeSchnellcheck();
  const [goNext, setGoNext] = useState(false);
  const tenant = searchParams.get("tenant");
  const location = useLocation(); // To get the current pathname

  const navigateToStep = (step: Steps, wasResumed = false) => {
    navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ""}${step}`);

    const pathWithTenant = `${location.pathname}${tenant ? `?tenant=${tenant}` : ""}${step}`;
    putMetric(pathWithTenant, "RenderPage");
    if (wasResumed) {
      putMetric(pathWithTenant, "ResumeFunnel");
    }
  };

  useEffect(() => {
    resumeSchnellcheck(leadId).then((response) => {
      if (response) {
        setSchnellcheck({ ...response, tenantName: response.tenant?.name });
        setReport(response.report);
        if (response.answers.houseOpenings) {
          navigateToStep(Steps.report, true);
        } else if (response.answers.houseInsulation) {
          navigateToStep(Steps.insulation, true);
        } else if (response.answers.houseHeating) {
          navigateToStep(Steps.heating, true);
        }
      } else if (renderIntroduction) {
        navigateToStep(Steps.introduction);
      }
    });
    if (leadId) {
      Smartlook.identify(leadId, {});
      retrieveRum()?.addSessionAttributes({ userId: leadId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    switch (hash) {
      case Steps.introduction:
        navigateToStep(Steps.start);
        break;
      case Steps.start:
        navigateToStep(Steps.heating);
        break;
      case Steps.heating:
        navigateToStep(Steps.insulation);
        break;
      case Steps.insulation:
        navigateToStep(Steps.openings);
        break;
      case Steps.openings:
        navigateToStep(Steps.report);
        break;
      case Steps.report:
        navigateToStep(Steps.offerRequest);
        break;
      case Steps.offerRequest:
        navigateToStep(Steps.confirmation);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.introduction:
        navigateToStep(Steps.introduction);
        break;
      case Steps.start:
        if (renderIntroduction) {
          navigateToStep(Steps.introduction);
        } else {
          navigateToStep(Steps.start);
        }
        break;
      case Steps.heating:
        navigateToStep(Steps.start);
        break;
      case Steps.insulation:
        navigateToStep(Steps.heating);
        break;
      case Steps.openings:
        navigateToStep(Steps.insulation);
        break;
      case Steps.report:
        navigateToStep(Steps.openings);
        break;
      case Steps.offerRequest:
        navigateToStep(Steps.report);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  useEffect(() => {
    if (upsertSchnellcheckResponse && !resuming) {
      setSchnellcheck({ ...upsertSchnellcheckResponse, tenantName: upsertSchnellcheckResponse.tenant?.name });
      setReport(upsertSchnellcheckResponse.report);
      toLocal(upsertSchnellcheckResponse.id);
      if (goNext) {
        setGoNext(false);
        next();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertSchnellcheckResponse, resuming]);

  useEffect(() => {
    const tenant = searchParams.get("tenant");
    const pathWithTenant = `${location.pathname}${tenant ? `?tenant=${tenant}` : ""}`;
    putMetric(pathWithTenant, "RenderStartPage", leadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAndNext = () => {
    setGoNext(true);
    upsertSchnellcheck(schnellcheck);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        direction='column'
        spacing={1}
        sx={{
          maxWidth: hash === Steps.confirmation ? "100%" : "lg",
          mx: "auto",
          bgcolor: "background.default",
          px: { xs: 0, lg: hash === Steps.confirmation ? 0 : 8 },
          pb: { xs: 0, lg: hash === Steps.confirmation ? 0 : 8 },
        }}
      >
        {isDesktop && <Title step={fromHash(hash)} tenant={tenant} embedded={embedded} />}
        {hash === Steps.introduction && referrerCode && <IntroductionScreen referrerCode={referrerCode} next={next} />}
        {hash === Steps.start && (
          <Screen1 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
        )}
        {hash === Steps.heating && (
          <Screen2 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
        )}
        {hash === Steps.insulation && (
          <Screen3 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
        )}
        {hash === Steps.openings && (
          <Screen4 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
        )}
        {hash === Steps.report && (
          <Screen5
            report={report}
            schnellcheck={schnellcheck}
            setSchnellcheck={setSchnellcheck}
            isUpdateRunning={upsertSchnellcheckRunning}
            prev={prev}
            next={next}
          />
        )}
        {hash === Steps.offerRequest && (
          <Screen6 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
        )}
        {hash === Steps.confirmation && <ConfirmationScreen embedded={embedded} />}
      </Stack>
    </Box>
  );
}

function Title({ step, tenant, embedded }: { tenant?: string | null; embedded?: boolean; step: Steps }) {
  switch (tenant) {
    case "hausbank":
      if (embedded) {
        return <Box py={2} flex={1} />;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return <UnbrandedTitle step={step} />;
  }
}

export const UnbrandedTitle = ({ step }: { step: Steps }) => {
  const kfwBafa = useImage("kfw-bafa.png");

  if (step === Steps.confirmation) {
    return null;
  }

  const renderIntroContent = () => {
    if (step === Steps.start) {
      return (
        <>
          <Grid size={12}>
            <Typography
              variant='h4'
              sx={{
                fontWeight: "fontWeightBold",
                lineHeight: 1.4,
                pb: 0,
                mx: 5,
                mt: 5,
              }}
            >
              Unsere Energieberater beraten Sie persönlich.
              <br />
              Kostenlos, unverbindlich, schnell
            </Typography>
          </Grid>
        </>
      );
    }
    if (step === Steps.introduction) {
      return (
        <>
          <Grid size={12}>
            <Typography
              variant='h4'
              sx={{
                fontWeight: "fontWeightBold",
                lineHeight: 1.4,
                pb: 0,
                mx: 5,
                mt: 5,
              }}
            >
              100% kostenlose Einschätzung für Ihre Immobilie
            </Typography>
          </Grid>
        </>
      );
    }
    return null;
  };
  return (
    <Grid container>
      {renderIntroContent()}
      <Grid size={4} sx={{ my: 4 }}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Förderfähig bei</Typography>
          <img src={kfwBafa.image} height={24} style={{ alignSelf: "center" }} />
        </Stack>
      </Grid>
      <Grid size={4} sx={{ my: 4 }}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Über 90% zufriedene Kunden</Typography>
        </Stack>
      </Grid>
      <Grid size={4} sx={{ my: 4 }}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Inkl. 100% Rundum-Sorglos-Paket</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
