import { Divider, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function SavingsEstimates({
  legacy,
  energyCostSavings,
  subsidy,
  trees,
}: {
  legacy?: boolean;
  energyCostSavings?: number;
  subsidy?: number;
  trees?: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Stack
      direction={"row"}
      component={Paper}
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
      sx={{ p: isMobile ? 1 : 2, backgroundColor: "#f6f6f6" }}
    >
      <Tooltip title='Keine Angst vor der Jahresabrechnung: Wir identifizieren für Sie die Maßnahmen, mit denen Sie kräftig sparen.'>
        <Stack flexGrow={1} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: isMobile ? "0.7rem" : "1rem",
              "&:before": {
                content: legacy ? `'${energyCostSavings?.toLocaleString("de-DE") ?? 0}€'` : `'-${energyCostSavings ?? 0}%'`,
                fontSize: isMobile ? "1.2rem" : "1.8rem",
                fontWeight: "fontWeightMedium",
                color: "#2E7D32",
                display: "block",
              },
            }}
            data-cy='energy-cost-text'
          >
            {legacy ? "Energieeinsparungen" : "Energiekosten"}
          </Typography>
        </Stack>
      </Tooltip>
      <Tooltip title='Ihr Haus hat Potenzial! Wenn Sie sich jetzt für eine Modernisierung entscheiden, können Sie noch von attraktiver Förderung profitieren. Wir helfen Ihnen dabei.'>
        <Stack flexGrow={1} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: isMobile ? "0.7rem" : "1rem",
              "&:before": {
                content: legacy ? `'${subsidy ?? 0}%'` : `'${subsidy?.toLocaleString("de-DE") ?? 0}€'`,
                fontSize: isMobile ? "1.2rem" : "1.8rem",
                fontWeight: "fontWeightMedium",
                color: "#2E7D32",
                display: "block",
              },
            }}
            data-cy='subsidy-text'
          >
            Zuschüsse
          </Typography>
        </Stack>
      </Tooltip>
      <Tooltip title='Beim Energiesparen auch noch Gutes tun? So viele Bäume müssen demnächst nicht mehr für Sie "arbeiten", sondern können helfen unser Klima zu verbessern.'>
        <Stack flexGrow={1} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: isMobile ? "0.7rem" : "1rem",
              "&:before": {
                content: `'${trees?.toLocaleString("de-DE") ?? 0}'`,
                fontSize: isMobile ? "1.2rem" : "1.8rem",
                fontWeight: "fontWeightMedium",
                color: "#2E7D32",
                display: "block",
              },
            }}
            data-cy='trees-text'
          >
            Bäume kompensiert
          </Typography>
        </Stack>
      </Tooltip>
    </Stack>
  );
}
