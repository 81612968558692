import Box from "@mui/material/Box";
import StatusQuoFunnel from "./StatusQuoFunnel";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

export default function ExampleEmbeddedStatusQuo() {
  return (
    <Stack
      alignContent={"center"}
      sx={{
        width: "100%",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e1e1e1",
        backgroundImage:
          "linear-gradient(-45deg,#ffcc0090 25%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 50%,#ffcc0090 50%,#ffcc0090 75%,rgba(0,0,0,0) 75%,rgba(0,0,0,0))",
        backgroundSize: "80px 80px",
      }}
    >
      <Typography
        variant='h2'
        sx={{
          my: 4,
          textShadow: "2px 2px 0px #FFFFFF, -2px 2px 0px #FFFFFF, 2px -2px 0px #FFFFFF, -2px -2px 0px #FFFFFF",
        }}
      >
        Embedded Schnellcheck
      </Typography>
      <Box
        sx={{
          width: "1136px",
          height: "978px",
          border: "1px solid #ccc",
          bgcolor: "background.default",
        }}
      >
        <StatusQuoFunnel embedded={true} />
      </Box>
    </Stack>
  );
}
