import LaunchIcon from "@mui/icons-material/Launch";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { useFetchSchnellcheck } from "../../../../hooks/useFetchSchnellcheck";
import { DisplayBubbleFlowSchnellcheckDialog } from "../../leads/leadlist/DisplayBubbleFlowSchnellcheckDialog";
import { DisplaySchnellcheckDialog } from "../../leads/leadlist/DisplaySchnellcheckDialog";
import { RowDetailProps } from "./types";

export default function IsfpSchnellcheckReady({ row }: RowDetailProps) {
  const { getSchnellcheck, schnellcheck } = useFetchSchnellcheck();
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const { getLead, lead } = useFetchLead();
  const [isBubbleSchnellCheckOpen, setBubbleSchnellCheckOpen] = useState(false);

  const toggleSchnellcheckModal = () => {
    setSchnellcheckOpen(!isSchnellcheckOpen);
  };

  const toggleBubbleSchnellCheckModal = () => {
    setBubbleSchnellCheckOpen(!isBubbleSchnellCheckOpen);
  };

  const openQuestionnaireDetails = async (questionnaireId?: string) => {
    if (!questionnaireId) {
      return;
    }
    getSchnellcheck(questionnaireId);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    setSchnellcheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schnellcheck]);

  useEffect(() => {
    setBubbleSchnellCheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Box>
      <Typography>
        Schnellcheck wurde{" "}
        {row.eventTimestamps?.schnellcheckFinishedOn ? (
          <>
            am <i>{formatDate(row.eventTimestamps?.schnellcheckFinishedOn, "dd.MM.yyyy")} </i>
          </>
        ) : (
          ""
        )}
        ausgefüllt
        {row.schnellCheckId && (
          <Box display={"inline"} pl={2}>
            <Link
              component='button'
              underline={"always"}
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
              onClick={() => openQuestionnaireDetails(row.schnellCheckId)}
            >
              <LaunchIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              {"Antworten ansehen"}
            </Link>
          </Box>
        )}
        {!row.schnellCheckId && (
          <Box display={"inline"} pl={2}>
            <Link
              component='button'
              underline={"always"}
              sx={{
                color: "text.primary",
                fontSize: "0.9rem",
                textDecorationColor: "inherit",
              }}
              onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
            >
              <LaunchIcon
                sx={{
                  fontSize: "1.2rem",
                  verticalAlign: "middle",
                }}
              />
              {"Antworten ansehen"}
            </Link>
          </Box>
        )}
      </Typography>
      <DisplaySchnellcheckDialog schnellcheck={schnellcheck!} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
      <DisplayBubbleFlowSchnellcheckDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleSchnellCheckOpen}
        handleClose={toggleBubbleSchnellCheckModal}
      />
    </Box>
  );
}
