export type PasswordValidationResult = {
  hasMinLength: boolean;
  hasSpecialChar: boolean;
  hasLowerCase: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
  passwordsMatch: boolean;
  passwordHasValue: boolean;
};

export const passwordValidator = (password: string, passwordRetyped?: string, minLength = 8): PasswordValidationResult => {
  if (!password) {
    return {
      hasLowerCase: false,
      hasMinLength: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasUpperCase: false,
      passwordsMatch: password == passwordRetyped,
      passwordHasValue: password != "",
    };
  }
  const lowerCaseRegex = /[a-z]+/g;
  const upperCaseRegex = /[A-Z]+/g;
  const numberRegex = /[0-9]+/g;
  const allowedSymbols = [
    "^",
    "$",
    "*",
    ".",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "?",
    "-",
    '"',
    "!",
    "@",
    "#",
    "%",
    "&",
    "/",
    "\\",
    ",",
    ">",
    "<",
    "'",
    ":",
    ";",
    "|",
    "_",
    "~",
    "`",
    "+",
    "=",
  ];

  return {
    hasLowerCase: lowerCaseRegex.test(password),
    hasMinLength: password.length >= minLength,
    hasNumber: numberRegex.test(password),
    hasSpecialChar: allowedSymbols.some((symbol) => password.includes(symbol)),
    hasUpperCase: upperCaseRegex.test(password),
    passwordsMatch: password == passwordRetyped,
    passwordHasValue: password != "",
  };
};
