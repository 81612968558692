import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, MobileStepper, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FunctionComponent, useEffect, useState, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import doors from "../../imgs/report_doors.png";
import windows from "../../imgs/report_windows.png";
import { DATA_CONSENT, HOUSE_OPENINGS } from "./storage";
import useUpsertQuestionnaire from "../../hooks/useUpsertQuestionnaire";
import { DoorType, HouseOpenings, WindowType } from "../../types/Schnellcheck";
import { ConsentLabel } from "../ConsentLabel";

type OpeningsProps = {
  question: number;
  setNextQuestion: (questionNbr: number) => void;
};

export const Openings: FunctionComponent<OpeningsProps> = ({ question, setNextQuestion }) => {
  const [upsertQuestionnaire] = useUpsertQuestionnaire();
  const [disabled, setDisabled] = useState<boolean>(true);
  const intl = useIntl();
  const [openings, setOpenings] = useState<HouseOpenings>({});
  const [consultantDataReviewConsent, setConsultantDataReviewConsent] = useState(false);

  function doorsTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newOpeningsState = {
      ...openings,
      doors: e.target.value as DoorType,
    };
    setOpenings(newOpeningsState);
    localStorage.setItem(HOUSE_OPENINGS, JSON.stringify(newOpeningsState));
  }

  function windowsTypeOnChange(e: ChangeEvent<HTMLInputElement>) {
    const newOpeningsState = {
      ...openings,
      windows: e.target.value as WindowType,
    };
    setOpenings(newOpeningsState);
    localStorage.setItem(HOUSE_OPENINGS, JSON.stringify(newOpeningsState));
  }

  useEffect(() => {
    setDisabled(!openings.doors || !openings.windows || !consultantDataReviewConsent);
  }, [openings, consultantDataReviewConsent]);

  function sendQuestionnaireOnClick(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    upsertQuestionnaire();
    setNextQuestion(4);
  }

  function updateConsultantChange(_: ChangeEvent<HTMLInputElement>, checked: boolean): void {
    setConsultantDataReviewConsent(checked);
    localStorage.setItem(DATA_CONSENT, JSON.stringify(checked));
  }

  useEffect(() => {
    // Local Storage
    const answer = localStorage.getItem(HOUSE_OPENINGS);
    if (answer) {
      const parsedAnswer: HouseOpenings = JSON.parse(answer);
      setOpenings(parsedAnswer);
    }
    const consent = localStorage.getItem(DATA_CONSENT);
    if (consent) {
      const parsedConsent: boolean = JSON.parse(consent);
      setConsultantDataReviewConsent(parsedConsent);
    }
  }, []);

  return (
    <>
      <Stack spacing={2} alignItems='center' style={{ width: "100%" }} p={3}>
        <Typography component='h2' style={{ fontSize: 20, fontWeight: 600, padding: 10 }}>
          <FormattedMessage id='app.openings.title' />
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} alignItems='center'>
              <img src={windows} className='Status-icons' alt={"Windows"} width='79' height='79' />
              <TextField
                id='windows-type'
                value={openings?.windows}
                label={intl.formatMessage({ id: "app.openings.windows" })}
                onChange={windowsTypeOnChange}
                size='small'
                select
                fullWidth
                data-cy='windows-input'
              >
                <MenuItem value={WindowType.simple} data-cy='simple-windows-input'>
                  <FormattedMessage id='app.openings.simple' />
                </MenuItem>
                <MenuItem value={WindowType.double} data-cy='double-windows-input'>
                  <FormattedMessage id='app.openings.double' />
                </MenuItem>
                <MenuItem value={WindowType.triple} data-cy='triple-windows-input'>
                  <FormattedMessage id='app.openings.triple' />
                </MenuItem>
              </TextField>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2} alignItems='center'>
              <img src={doors} className='Status-icons' alt={"Doors"} width='79' height='79' />
              <TextField
                id='doors-type'
                value={openings?.doors}
                label={intl.formatMessage({ id: "app.openings.doors" })}
                onChange={doorsTypeOnChange}
                size='small'
                select
                fullWidth
                data-cy='doors-input'
              >
                <MenuItem value={DoorType.steel} data-cy='stell-doors-input'>
                  <FormattedMessage id='app.openings.steel' />
                </MenuItem>
                <MenuItem value={DoorType.wood} data-cy='wood-doors-input'>
                  <FormattedMessage id='app.openings.wood' />
                </MenuItem>
                <MenuItem value={DoorType.plastic} data-cy='plastic-doors-input'>
                  <FormattedMessage id='app.openings.plastic' />
                </MenuItem>
              </TextField>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6} textAlign={"right"}>
            <FormControlLabel
              sx={{ pr: 0, mr: 0 }}
              control={
                <Checkbox
                  data-cy='terms-option'
                  name='checkbox-consent'
                  color='secondary'
                  checked={consultantDataReviewConsent}
                  onChange={updateConsultantChange}
                />
              }
              label={<ConsentLabel fontSize={11} required />}
            />
          </Grid>
        </Grid>
        <MobileStepper
          variant='dots'
          steps={6}
          activeStep={question}
          position='top'
          sx={{ flexGrow: 1, position: "relative", alignItems: "center", width: "100%" }}
          nextButton={
            <Button variant='contained' size='small' data-cy='openings-next-btn' onClick={(e) => sendQuestionnaireOnClick(e)} disabled={disabled}>
              <FormattedMessage id='app.openings.next' /> <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button variant='contained' size='small' onClick={() => setNextQuestion(2)} disabled={question === 0}>
              <KeyboardArrowLeft /> <FormattedMessage id='app.openings.back' />
            </Button>
          }
        />
      </Stack>
    </>
  );
};
