import { Button, Stack, TextField, Typography } from "@mui/material";
import { ChangeEventHandler, FunctionComponent, MouseEventHandler } from "react";

type ContactCardProps = {
  email: string;
  emailOnChange: ChangeEventHandler;
  firstName: string;
  firstNameOnChange: ChangeEventHandler;
  lastName: string;
  lastNameOnChange: ChangeEventHandler;
  telephone: string;
  telephoneOnChange: ChangeEventHandler;
  sendQuestionnaireFunc: MouseEventHandler;
  buttonDisabled: boolean;
};

export const ContactCard: FunctionComponent<ContactCardProps> = ({
  email,
  emailOnChange,
  firstName,
  firstNameOnChange,
  lastName,
  lastNameOnChange,
  telephone,
  telephoneOnChange,
  sendQuestionnaireFunc,
  buttonDisabled,
}) => {
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 6,
          justifyContent: "space-around",
          height: "100%",
          p: {
            xs: 3,
            md: 6,
          },
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: "28px", color: "#000000" }}>Angebot sichern</Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "16px", color: "#000000", py: 0 }}>Individueller Sanierungsfahrplan (iSFP) ab 395€</Typography>
        <TextField
          required
          id='first-name-input'
          value={firstName}
          placeholder='Vorname'
          variant='outlined'
          size='small'
          onChange={firstNameOnChange}
          fullWidth
          data-cy='first-name-input'
        />
        <TextField
          required
          id='last-name-input'
          value={lastName}
          placeholder='Nachname'
          variant='outlined'
          size='small'
          onChange={lastNameOnChange}
          fullWidth
          data-cy='last-name-input'
        />
        <TextField
          required
          id='email'
          value={email}
          placeholder='Email'
          variant='outlined'
          size='small'
          onChange={emailOnChange}
          fullWidth
          data-cy='email-input'
        />
        <TextField
          required
          id='telephone'
          value={telephone}
          placeholder='Telefonnummer'
          variant='outlined'
          type='number'
          size='small'
          onChange={telephoneOnChange}
          fullWidth
          data-cy='telephone-input'
        />
        <Button
          variant='contained'
          sx={{ fontSize: "20px", borderRadius: 4, lineHeight: 1.25, marginTop: "40px" }}
          onClick={sendQuestionnaireFunc}
          disabled={buttonDisabled}
          data-cy='submit-offer-request-btn'
        >
          Kostenloses Erstgespräch & Angebot anfordern
        </Button>
      </Stack>
    </>
  );
};
