import { Link, Typography } from "@mui/material";

export type ConsentLabelProps = {
  termsConsentError?: boolean;
  fontSize?: number;
  // this renders an additional "*" asterisk if the checkbox itself is not set to requried
  required?: boolean;
};

export const ConsentLabel = ({ termsConsentError, fontSize, required }: ConsentLabelProps) => {
  return (
    <Typography display={"inline"} fontSize={fontSize} color={termsConsentError ? "error.main" : "default"}>
      Ich akzeptiere die{" "}
      <Link target='_blank' href='/terms-and-conditions' color={termsConsentError ? "error.main" : "text.primary"}>
        Nutzungsbedingungen.
      </Link>
      {required && <sup> *</sup>}
    </Typography>
  );
};
