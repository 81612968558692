import { Typography, Stack, Alert, Fade, Checkbox, FormControlLabel, Box, Tab, Tabs } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import { subDays } from "date-fns";
import useFetchMetrics from "../../../hooks/useFetchMetrics";
import useFetchTenantsMetrics from "../../../hooks/useFetchTenantsMetrics";
import useTriggerMetricsEmail from "../../../hooks/useTriggerMetricsEmail";
import TenantsMetrics from "./TenantsMetrics";
import GeneralMetrics from "./GeneralMetrics";
import { ConsultantMetricsEventCounts, EventType, TenantMetrics } from "../../../types/ConsultantMetrics";

const compileTotalMetrics = (tenantsMetrics: TenantMetrics[]): ConsultantMetricsEventCounts => {
  const totals = Object.values(EventType)
    .map((eventType) => ({ [eventType]: 0 }) as { [key in EventType]: number })
    .reduce((a, b) => ({ ...a, ...b }));

  for (const tenantMetrics of tenantsMetrics) {
    const rangeName = Object.keys(tenantMetrics.metrics).shift();
    if (!rangeName) {
      continue;
    }
    const rangeMetrics = tenantMetrics.metrics[rangeName];
    const metricKeys = Object.keys(rangeMetrics);

    for (const metricKey of metricKeys) {
      const key = metricKey as EventType;
      totals[key] += rangeMetrics[key];
    }
  }
  return totals as ConsultantMetricsEventCounts;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function MetricsList() {
  const [from, setFrom] = useState<Date | null>(subDays(new Date(), 7));
  const [to, setTo] = useState<Date | null>(new Date());
  const [allData, setAllData] = useState(false);
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);
  const [showError, setShowError] = useState(false);
  const { fetchMetrics, error: mError, isLoading: mIsLoading, data: generalMetrics } = useFetchMetrics();
  const { fetchTenantsMetrics, error: tmError, isLoading: tmIsLoading, metrics: tenantsMetrics } = useFetchTenantsMetrics();
  const { triggerMetricsEmail, triggerMetricsEmailError, triggerMetricsEmailLoading, triggerResponseStatus } = useTriggerMetricsEmail();
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleSubmit = () => {
    if (!allData && !from) {
      setFromError(true);
      return;
    }
    if (!allData && !to) {
      setToError(true);
      return;
    }
    fetchMetrics(from?.getTime(), to?.getTime(), allData);
    const metricRanges = allData ? undefined : { range: { from: from!.getTime(), to: to!.getTime() } };
    fetchTenantsMetrics(metricRanges);
  };

  const handleAllDataChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAllData(checked);
  };

  useEffect(() => {
    if (mError || tmError) {
      setShowError(true);
    }
  }, [mError, tmError]);

  return (
    <Stack spacing={2} padding={2}>
      <Typography variant='h5' style={{ fontWeight: 600 }}>
        Metriken
      </Typography>
      <ButtonWithSpinner
        variant={"contained"}
        loading={triggerMetricsEmailLoading}
        label='Receive all reports by email'
        onClick={() => triggerMetricsEmail()}
        sx={{ height: 40, maxWidth: 300 }}
      />
      <Fade in={!!triggerMetricsEmailError} timeout={500} unmountOnExit>
        <Alert sx={{ marginTop: 2 }} severity='error'>
          There was an error triggering the reports.
        </Alert>
      </Fade>
      <Fade in={triggerResponseStatus === 200} timeout={500} unmountOnExit>
        <Alert sx={{ marginTop: 2 }} severity='success'>
          The reports are being generated. Your email will be sent soon.
        </Alert>
      </Fade>
      <FormControlLabel control={<Checkbox required={false} value={allData} onChange={handleAllDataChange} />} label='Query all available data' />
      <Stack direction='row' spacing={2} style={{ width: "100%" }} alignItems={"center"}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
          <DatePicker label='From' value={from} disabled={allData} onChange={(newValue) => setFrom(newValue)} slotProps={{ textField: { error: fromError } }} />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
          <DatePicker label='To' value={to} disabled={allData} onChange={(newValue) => setTo(newValue)} slotProps={{ textField: { error: toError } }} />
        </LocalizationProvider>
        <ButtonWithSpinner variant={"contained"} loading={mIsLoading || tmIsLoading} label='Load' onClick={handleSubmit} sx={{ height: 40 }} />
      </Stack>
      <Fade in={showError} timeout={500} unmountOnExit>
        <Alert sx={{ marginTop: 2 }} severity='error'>
          There was an error fetching metrics.
        </Alert>
      </Fade>
      {!!tenantsMetrics && !!generalMetrics && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label='Totals' />
              <Tab label='Per tenant' />
            </Tabs>
          </Box>

          <CustomTabPanel value={tab} index={0}>
            <GeneralMetrics pageViews={generalMetrics.pageViews} totals={compileTotalMetrics(tenantsMetrics.tenantMetrics)} />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <TenantsMetrics
              tenantsMetrics={tenantsMetrics.tenantMetrics}
              accountCreation={generalMetrics.accountCreation}
              authentication={generalMetrics.authentication}
            />
          </CustomTabPanel>
        </>
      )}
    </Stack>
  );
}
