import { AnalysisState, Answers, SchnellcheckSource, UpsertQuestionnaireRequest } from "../../types/Schnellcheck";

export const HOUSE_DETAILS = "houseDetails";
export const ANALYSIS = "analysis";
export const CONTACT = "contact";
export const HOUSE_OPENINGS = "houseOpenings";
export const HOUSE_INSULATION = "houseInsulation";
export const DATA_CONSENT = "consultantDataReviewConsent";

export const getLocalAnswers = (leadId?: string): UpsertQuestionnaireRequest => {
  const STATES = [HOUSE_DETAILS, HOUSE_INSULATION, HOUSE_OPENINGS, CONTACT, DATA_CONSENT];

  const answers: { [prop: string]: unknown } = {};
  for (const question of STATES) {
    const answerString = localStorage.getItem(question);
    if (answerString) {
      answers[question] = JSON.parse(answerString);
    }
  }

  let analysis;
  const analysisStr = localStorage.getItem(ANALYSIS);
  if (analysisStr) {
    analysis = JSON.parse(analysisStr);
  }

  return { answers: answers as Answers, id: analysis?.id, leadId, source: SchnellcheckSource.embedded };
};

export const storeLocalReport = (response: AnalysisState): void => {
  localStorage.setItem(ANALYSIS, JSON.stringify(response));
};
