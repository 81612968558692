import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack, Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IntroScreen from "./IntroScreen";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Steps } from "./utils";
import ConsentSnackbar from "../Consent";
import GeneralInfoScreen from "./GeneralInfoScreen";
import OuterWallsScreen from "./OuterWallsScreen";
import AtticScreen from "./AtticScreen";
import BasementScreen from "./BasementScreen";
import DoorsAndWindowsScreen from "./DoorsAndWindowsScreen";
import HotWaterAndVentilationScreen from "./HotWaterAndVentilationScreen";
import CustomerPreferencesScreen from "./CustomerPreferencesScreen";
import FinalScreen from "./FinalScreen";
import PhotoUploadScreen from "./PhotoUploadScreen";
import PlansAndDocumentsUploadScreen from "./PlansAndDocumentsUploadScreen";
import Smartlook from "smartlook-client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FloorsScreen from "./FloorsScreen";
import PhotovoltaicsScreen from "./PhotovoltaicsScreen";
import { UpsertQuestionnaireRequest } from "../../../../types/RenovationQuestionnaire";
import { ScreenProps } from "../FormScreen";
import useUpsertRenovations from "../../../../hooks/useUpsertRenovations";
import useResumeRenovations from "../../../../hooks/useResumeRenovations";
import usePutMetric from "../../../../hooks/usePutMetric";
import { retrieveRum } from "../../../../AwsRum";
import { HouseType } from "../../../../types/Schnellcheck";

export type RenovationsScreenProps = ScreenProps & {
  renovations: UpsertQuestionnaireRequest;
  setRenovations: Dispatch<SetStateAction<UpsertQuestionnaireRequest>>;
};

export default function RenovationsFunnel() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [putMetric] = usePutMetric();
  const leadId = searchParams.get("leadId") ?? undefined;
  const yearParam = searchParams.get("constructionYear") ?? undefined;
  const constructionYear = yearParam ? parseInt(yearParam) : undefined;
  const houseTypeParam = searchParams.get("houseType") ?? undefined;
  const houseType = houseTypeParam ? HouseType[houseTypeParam as keyof typeof HouseType] : undefined;
  const [renovations, setRenovations] = useState<UpsertQuestionnaireRequest>({ leadId, constructionYear, houseType, isFinal: false });
  const { upsertRenovations, upsertRenovationsRunning, upsertRenovationsResponse } = useUpsertRenovations();
  const { resumeRenovations, toLocal, resuming } = useResumeRenovations();
  const [goNext, setGoNext] = useState(false);
  const tenant = searchParams.get("tenant");
  const location = useLocation(); // To get the current pathname
  const pathWithTenant = `${location.pathname}${tenant ? `?tenant=${tenant}` : ""}`;

  const navigateToStep = (step: Steps, wasResumed = false) => {
    const pathWithHash = `${pathWithTenant}${step}`;
    putMetric(pathWithHash, "RenderPage");
    if (wasResumed) {
      putMetric(pathWithHash, "ResumeFunnel");
    }
    navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ""}${step}`);
  };

  useEffect(() => {
    resumeRenovations(leadId).then((response) => {
      if (response) {
        setRenovations(response);
        if (response.isFinal) {
          navigateToStep(Steps.final, true);
        } else if (response.answers?.housePhotos) {
          navigateToStep(Steps.plansAndDocumentsUpload, true);
        } else if (response.answers?.customerPreferences) {
          navigateToStep(Steps.photoUpload, true);
        } else if (response.answers?.renewablesInformation) {
          navigateToStep(Steps.customerPreferences, true);
        } else if (response.answers?.hotWaterAndVentilationInformation) {
          navigateToStep(Steps.photovoltaics, true);
        } else if (response.answers?.windowsAndDoorsInformation) {
          navigateToStep(Steps.hotWaterVentilation, true);
        } else if (
          (response.answers?.basementOrGroundInformation?.basement && response.answers?.basementOrGroundInformation?.basement !== "none") ||
          (response.answers?.basementOrGroundInformation?.basement === "none" && response.answers?.basementOrGroundInformation?.groundFloorInsulation)
        ) {
          navigateToStep(Steps.doorsWindows, true);
        } else if (response.answers?.atticOrRoofInformation) {
          navigateToStep(Steps.basement, true);
        } else if (response.answers?.exteriorWalls) {
          navigateToStep(Steps.attic, true);
        } else if (response.answers?.houseConfiguration) {
          navigateToStep(Steps.outerWalls, true);
        } else if (response.answers?.houseInformation) {
          navigateToStep(Steps.floors, true);
        }
        putMetric(pathWithTenant, "ResumeFunnel");
      }
    });
    if (leadId) {
      Smartlook.identify(leadId, {});
      retrieveRum()?.addSessionAttributes({ userId: leadId });
      putMetric(pathWithTenant, "RenderStartPage", leadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    switch (hash) {
      case Steps.start:
        navigateToStep(Steps.general);
        break;
      case Steps.general:
        navigateToStep(Steps.floors);
        break;
      case Steps.floors:
        navigateToStep(Steps.outerWalls);
        break;
      case Steps.outerWalls:
        navigateToStep(Steps.attic);
        break;
      case Steps.attic:
        navigateToStep(Steps.basement);
        break;
      case Steps.basement:
        navigateToStep(Steps.doorsWindows);
        break;
      case Steps.doorsWindows:
        navigateToStep(Steps.hotWaterVentilation);
        break;
      case Steps.hotWaterVentilation:
        navigateToStep(Steps.photovoltaics);
        break;
      case Steps.photovoltaics:
        navigateToStep(Steps.customerPreferences);
        break;
      case Steps.customerPreferences:
        navigateToStep(Steps.photoUpload);
        break;
      case Steps.photoUpload:
        navigateToStep(Steps.plansAndDocumentsUpload);
        break;
      case Steps.plansAndDocumentsUpload:
        navigateToStep(Steps.final);
        break;
      case Steps.final:
        navigateToStep(Steps.start);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.general:
        navigateToStep(Steps.start);
        break;
      case Steps.floors:
        navigateToStep(Steps.general);
        break;
      case Steps.outerWalls:
        navigateToStep(Steps.floors);
        break;
      case Steps.attic:
        navigateToStep(Steps.outerWalls);
        break;
      case Steps.basement:
        navigateToStep(Steps.attic);
        break;
      case Steps.doorsWindows:
        navigateToStep(Steps.basement);
        break;
      case Steps.hotWaterVentilation:
        navigateToStep(Steps.doorsWindows);
        break;
      case Steps.photovoltaics:
        navigateToStep(Steps.hotWaterVentilation);
        break;
      case Steps.customerPreferences:
        navigateToStep(Steps.photovoltaics);
        break;
      case Steps.photoUpload:
        navigateToStep(Steps.customerPreferences);
        break;
      case Steps.plansAndDocumentsUpload:
        navigateToStep(Steps.photoUpload);
        break;
      case Steps.final:
        navigateToStep(Steps.plansAndDocumentsUpload);
        break;
      default:
        navigateToStep(Steps.start);
    }
  };

  useEffect(() => {
    if (upsertRenovationsResponse && !resuming) {
      setRenovations(upsertRenovationsResponse);
      if (upsertRenovationsResponse.id) {
        toLocal(upsertRenovationsResponse.id);
      }
      if (goNext) {
        setGoNext(false);
        next();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertRenovationsResponse, resuming]);

  const saveAndNext = (payload: UpsertQuestionnaireRequest) => {
    const reqBody = payload ?? renovations;
    setGoNext(true);
    upsertRenovations(reqBody);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <Backdrop sx={(theme) => ({ color: "#ffffff", zIndex: theme.zIndex.drawer + 1 })} open={resuming}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            maxWidth: "lg",
            mx: "auto",
            bgcolor: "background.default",
            px: { xs: 0, lg: 8 },
            pb: 4,
          }}
        >
          <Title step={hash} />
          {hash === Steps.start && (
            <IntroScreen renovations={renovations} isUpdateRunning={upsertRenovationsRunning} setRenovations={setRenovations} prev={prev} next={next} />
          )}
          {hash === Steps.general && (
            <GeneralInfoScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.floors && (
            <FloorsScreen renovations={renovations} isUpdateRunning={upsertRenovationsRunning} setRenovations={setRenovations} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.outerWalls && (
            <OuterWallsScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.attic && (
            <AtticScreen renovations={renovations} isUpdateRunning={upsertRenovationsRunning} setRenovations={setRenovations} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.basement && (
            <BasementScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.doorsWindows && (
            <DoorsAndWindowsScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.hotWaterVentilation && (
            <HotWaterAndVentilationScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.photovoltaics && (
            <PhotovoltaicsScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.customerPreferences && (
            <CustomerPreferencesScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.photoUpload && (
            <PhotoUploadScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.plansAndDocumentsUpload && (
            <PlansAndDocumentsUploadScreen
              renovations={renovations}
              isUpdateRunning={upsertRenovationsRunning}
              setRenovations={setRenovations}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.final && (
            <FinalScreen renovations={renovations} isUpdateRunning={upsertRenovationsRunning} setRenovations={setRenovations} prev={prev} next={saveAndNext} />
          )}
        </Stack>
      </Box>
      <ConsentSnackbar smartlookId='5abe1187b106b0cb2e77b582a599550690eca13f' metricName='Sanierungscheck' />
    </Box>
  );
}

function Title({ step }: { step: string }) {
  return (
    <Grid container>
      {step === Steps.start && (
        <Grid size={12}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: "fontWeightBold",
              fontSize: { xs: 20, lg: 34 },
              lineHeight: 1.4,
              pb: 0,
              m: { xs: 3, lg: 5 },
            }}
          >
            Willkommen zum iSFP-Fragebogen
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
