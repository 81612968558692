import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { awsRumParams } from "./utils/params";

const client: { rum?: AwsRum } = {};
const RUM_DATAPLANE_URL = "https://dataplane.rum.eu-central-1.amazonaws.com";

export const retrieveRum = () => {
  if (client.rum) {
    return client.rum;
  }
  try {
    const params = awsRumParams();
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      endpoint: RUM_DATAPLANE_URL,
      telemetries: [
        [
          "errors",
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ignore: (errorEvent: any) => {
              const message = errorEvent?.message ?? errorEvent?.error?.message ?? "";

              return /Object Not Found Matching Id/.test(message);
            },
          },
        ],
        "performance",
        [
          "http",
          {
            addXRayTraceIdHeader: true,
            urlsToExclude: [new RegExp(`${RUM_DATAPLANE_URL}/.+`)],
          },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
      identityPoolId: params.identityPoolId,
    };

    if (params.applicationId) {
      client.rum = new AwsRum(params.applicationId, "1.0.0", "eu-central-1", config);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_: unknown) {
    // do nothing
  }
  return client.rum;
};
