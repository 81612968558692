import { createTheme } from "@mui/material/styles";
import { Themes } from "../types/types";
import { deDE } from "@mui/material/locale";

const hausbank = createTheme(
  {
    name: Themes.HAUSBANK,
    logo: "Hausbank_by_NOVO.png",
    palette: {
      mode: "light",
      primary: {
        main: "#0071b2",
        light: "#38b3fa",
        dark: "#03507c",
        contrastText: "#fff",
      },
      secondary: {
        main: "#727776",
        light: "#B3B6B6",
        dark: "#444F4F",
      },
      success: {
        main: "#00AC4F",
      },
      error: {
        main: "#D0004B",
      },
      background: {
        default: "#ffffff",
        paper: "#fdfdfd",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
        secondary: "rgba(0,0,0,0.6)",
        disabled: "rgba(0,0,0,0.38)",
      },
    },
    typography: {
      fontFamily: ['"Segoe UI"', "Arial", "sans-serif", '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 4,
    },
  },
  deDE,
);

export default hausbank;
