import { useContext } from "react";
import { IsfpProcessStatus, IsfpProperty, Lead, Product, ProductRequest, Property } from "../../../../types/cockpit/types";
import { AuthContext } from "../../../../Contexts";
import { CallModeType, DoorType, HeatingSystemType, HouseType, SchnellcheckSource, StatusColor, WindowType } from "../../../../types/Schnellcheck";
import {
  ComponentEquipmentEnum,
  ConsumptionCertificateGenState,
  ConsumptionCertificateItem,
  RenovationCombiEnum,
} from "../../../../types/ConsumptionCertificateState";

export const EXAMPLE_LEAD_ID_PREFIX: string = "example-lead-id";
export const EXAMPLE_ISFP_ID_PREFIX: string = "example-isfp-id";
export const SAMPLE_ISFP_DOCUMENT_URL = `${window.location.protocol}//${window.location.host}/sample-iSFP.pdf`;
export const EXAMPLE_CC_ID_PREFIX: string = "example-cc-id";
export const SAMPLE_CC_DOCUMENT_URL = `${window.location.protocol}//${window.location.host}/sample-energieverbrauchausweis.pdf`;
const now = new Date().getTime();

export function usePlaceholderLeadData(): Lead[] {
  const { user } = useContext(AuthContext);
  return [
    {
      id: `${EXAMPLE_LEAD_ID_PREFIX}-1`,
      name: "Max Mustermann",
      email: "max.mustermann@privat-email.de",
      productInvites: getProductInvites(),
      referrer: user?.email, //consultant email
      tenant: user?.tenant?.id,
      consultantDataReviewConsent: true,
      properties: getProperties(),
      createdAt: now,
    },
  ];
}

function getProductInvites(): ProductRequest[] {
  return [
    {
      product: Product.isfp,
      isFree: false,
      createdAt: now,
    },
    {
      product: Product.consumptionCertificate,
      isFree: false,
      createdAt: now,
    },
    {
      product: Product.onePager,
      isFree: false,
      createdAt: now,
    },
  ];
}

function getProperties(): Property[] {
  return [
    {
      isfp: generateIsfp(),
      consumptionCertificate: generateConsumptionCertificate(),
    },
  ];
}

function generateIsfp(): IsfpProperty {
  return {
    id: `${EXAMPLE_ISFP_ID_PREFIX}-1`,
    leadId: `${EXAMPLE_LEAD_ID_PREFIX}-1`,
    leadName: "Max Mustermann",
    leadEmail: "max.mustermann@privat-email.de",
    status: IsfpProcessStatus.FINISHED,
    schnellcheck: {
      id: "status-quo-questionnaire-1",
      source: SchnellcheckSource.standalone,
      leadId: `${EXAMPLE_LEAD_ID_PREFIX}-1`,
      lastUpdatedAt: now,
      answers: {
        houseDetails: {
          area: 120,
          yearOfConstruction: 1990,
          type: HouseType.singleFamily,
          isMonument: false,
        },
        houseHeating: {
          type: HeatingSystemType.districtHeating,
          yearOfInstalation: 1990,
        },
        houseInsulation: {
          yearOfBasementInsulation: 2001,
          yearOfRoofInsulation: 2001,
        },
        houseOpenings: {
          doors: DoorType.wood,
          windows: WindowType.double,
          doorsYear: 1990,
        },
        contact: {
          email: "max.mustermann@privat-email.de",
          telephone: "+491000100100",
          callMode: CallModeType["Book a call"],
          firstName: "Max",
          lastName: "Mustermann",
          address: "Maxstr. 1, 10100 Berlin",
        },
      },
      report: {
        energyCostSavings: 65,
        subsidy: 4000,
        trees: 60,
        currentEnergyClass: "D",
        afterRenovationEnergyClass: "A",
        status: {
          heating: StatusColor.red,
          windows: StatusColor.yellow,
          roof: StatusColor.green,
          envelope: StatusColor.green,
          basement: StatusColor.yellow,
          doors: StatusColor.yellow,
        },
      },
    },
    renovationQuestionnaire: {
      id: "renovation-questionnaire-1",
      leadId: `${EXAMPLE_LEAD_ID_PREFIX}-1`,
      constructionYear: 1990,
      houseType: HouseType.singleFamily,
      createdAt: now,
      lastUpdatedAt: now,
      isFinal: true,
      answers: {
        houseInformation: {
          houseTypology: "detached",
          units: 1,
          plannedExpansion: true,
        },
        houseConfiguration: {
          floors: 2,
          groundFloorHeight: 300,
          upperFloorsHeight: 240,
          atticHeight: 240,
          basementHeight: 200,
        },
        exteriorWalls: {
          wallsMaterial: "Beton",
          insulation: {
            installed: true,
            year: 2000,
            thickness: 20,
            material: "styrofoam",
          },
        },
        atticOrRoofInformation: {
          roofTypology: "gabled",
          gabledRoofInsulation: {
            installed: true,
            year: 2000,
            thickness: 20,
            material: "styrofoam",
          },
        },
        basementOrGroundInformation: {
          basement: "partiallyHeated",
          hotWallsInsulation: {
            installed: true,
            year: 2000,
            thickness: 20,
            material: "styrofoam",
          },
          hotFloorInsulation: {
            installed: true,
            year: 2000,
            thickness: 20,
            material: "styrofoam",
          },
          coldCeilingInsulation: {
            installed: true,
            year: 2000,
            thickness: 20,
            material: "styrofoam",
          },
        },
        windowsAndDoorsInformation: {
          windowsFrame: "metal",
          windowInstallationYear: 1990,
          otherFrameTypes: true,
          doorsMaterial: "wood",
        },
        hotWaterAndVentilationInformation: {
          waterHeating: "sameAsHeating",
          hasWaterBoiler: "yes",
          boilerCapacity: 150,
          ventilation: "other",
          otherVentilation: "Liquid cooling",
        },
        renewablesInformation: {
          photovoltaicsInstalled: true,
          photovoltaicsCapacity: 25,
          photovoltaicsInstallationYear: 2020,
          isFullfeed: false,
          isBatteryInstalled: true,
          batteryCapacity: 25,
          batteryInstallationYear: 2020,
          solarInstalled: true,
          solarCapacity: 25,
        },
        customerPreferences: {
          preferredHeating: "heatPump",
          renovationPlans: ["photovoltaic", "windows"],
        },
      },
    },
  };
}

function generateConsumptionCertificate(): ConsumptionCertificateItem {
  return {
    id: `${EXAMPLE_CC_ID_PREFIX}-1`,
    answers: {
      reason: "",
      typeOfBuilding: "Einfamilienhaus",
      area: 120,
      yearOfConstruction: 1990,
      reform: "Dach und Heizung",
      numberOfApartments: 1,
      address: "Maxstr. 1, 10100 Berlin",
      postalCode: "10100",
      location: "Berlin",
      bundesland: "Berlin",
      hasHeatedBasement: true,

      heatingSystemType: "Erdgas",
      yearOfHeatingInstalation: 1990,
      endMonthOfBill: "10",
      yearOfBill: 2020,
      verbrauch1: 4000,
      verbrauch2: 4000,
      verbrauch3: 4000,

      ventilationType: ["Fensterlüftung"],
      coolingSystems: [{ system: "Kühlung aus Strom", surface: 100 }],
      renewable: ["Solarthermie"],
      renewableUsage: ["Wassererwärmung"],

      hotWaterSystems: [
        {
          type: "Erdgas",
          isPartOfHeating: true,
          isCentralized: true,
        },
      ],

      modernisationRecommendations: [
        {
          number: 1,
          componentEquipment: ComponentEquipmentEnum.Roof,
          description: "Dachdämmung",
          renovationCombination: RenovationCombiEnum.AsIndividualMeasure,
          amortisation: "500",
          specificCosts: "5000",
        },
      ],
    },
    document: {
      state: ConsumptionCertificateGenState.Ready,
      registrationNumber: "XXXXXXXXXX-01",
    },
    state: "final",
    lastUpdatedAt: now,
    createdAt: now,
  };
}
