import { Typography } from "@mui/material";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { RowDetailProps } from "./types";
import { formatDate } from "date-fns";

export default function IsfpRenovationInProgress({ row }: RowDetailProps) {
  return (
    <Typography>
      {row.status == IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_STARTED && (
        <>
          iSFP-Fragebogen wird ausgefüllt
          {row.eventTimestamps?.renovationQuestionaireStartedOn && (
            <>
              . Er wurde am <i>{formatDate(row.eventTimestamps?.renovationQuestionaireStartedOn, "dd.MM.yyyy")} </i> begonnen
            </>
          )}
          .
        </>
      )}
    </Typography>
  );
}
